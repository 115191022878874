import {
  Box,
  Card,
  Grid,
  Collapse,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  IconButton,
  InputAdornment
} from '@mui/material'
import { FileOpen } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { memo, useReducer, useEffect } from 'react'
import {
  AutocompleteText2,
  Dialog,
  DialogText,
  HeaderBox,
  RenderingSuppress,
  MoreIconButton,
  StyledCell,
  LoadButton,
  TextField,
  TextPassword,
  FileDragArea,
  Loading,
  Checkbox
} from 'src/components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useApi, useStateManage } from 'src/util/'

export default function SciRaWsSearchMore() {
  console.log('SciRaWsSearchMore')
  const sm = useStateManage({
    1: { open: false },
    2: { open: false },
    3: { open: false }
  })

  const itemList = [
    { itemName: 'SCI-WS設定の登録', call: () => sm.setObject({ 1: { open: true } }) },
    { itemName: 'SCI-WS設定の変更・削除', call: () => sm.setObject({ 2: { open: true } }) }
  ]

  return (
    <>
      <MoreIconButton itemList={itemList} sx={{ my: '-8px' }} />
      <RenderingSuppress show={sm.state[1].open}>
        <SciWsSettingRegister dialog={sm.state[1]} setDialog={(obj) => sm.setObject({ 1: obj })} />
      </RenderingSuppress>
      <RenderingSuppress show={sm.state[2].open}>
        <SciWsSettingEdit dialog={sm.state[2]} setDialog={(obj) => sm.setObject({ 2: obj })} />
      </RenderingSuppress>
    </>
  )
}

const BASE64_REGEX = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

const ContentForm = ({ form }) => {
  const handleFileSelect = (e) => {
    console.log('handleFileSelect')
    const elem = document.getElementById('file-select-3')
    const file = elem.files[0]
    if (!file) return
    if (file.size > 32000) {
      alert('ファイルサイズが32,000バイトを超えています。')
      return
    }
    const reader = new FileReader()
    reader.onload = (event) => {
      let data = reader.result
      const str = String.fromCharCode.apply(null, new Uint8Array(data))
      if (!BASE64_REGEX.test(str.trim())) {
        data = window.btoa(str)
      }
      form.setValue('sciws_keystore_name', file.name)
      form.setValue('sciws_keystore_base64', data)
    }
    reader.readAsArrayBuffer(file)
    elem.value = ''
  }

  return (
    <DialogContent sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField form={form} name="sciws_name" label="SCI-WS設定名*" inputProps={{ maxLength: 32 }} autoFocus />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField form={form} name="description" label="説明" inputProps={{ maxLength: 64 }} />
        </Grid>
        <Grid item xs={12}>
          <TextField form={form} name="sciws_url" label="URL（WSDL）*" inputProps={{ maxLength: 250 }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <input hidden type="file" id="file-select-3" onChange={handleFileSelect} style={{ display: 'none' }} />
          <FileDragArea fileId="file-select-3" callback={handleFileSelect}>
            <TextField
              form={form}
              name="sciws_keystore_name"
              label="キーストアファイル*（.p12 .pfx）"
              sx={{ input: { cursor: 'pointer' } }}
              onClick={() => document.getElementById('file-select-3').click()}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" color="inherit">
                    <IconButton edge="end" size="small">
                      <FileOpen />
                    </IconButton>
                  </InputAdornment>
                ),
                readOnly: 1
              }}
            />
          </FileDragArea>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextPassword form={form} name="sciws_password" label="パスワード*" />
        </Grid>
      </Grid>
    </DialogContent>
  )
}

ContentForm.propTypes = {
  form: PropTypes.object
}

const SCIWS_SETTING_FORM = yup.object({
  sciws_name: yup.string().trim().required('SCI-WS設定名は必須項目です。'),
  sciws_url: yup.string().trim().required('URLは必須項目です。'),
  sciws_keystore_name: yup.string().trim().required('キーストアファイルは必須項目です。'),
  // sciws_keystore_base64: yup.string().trim().required('キーストアファイルは必須項目です。'),
  sciws_password: yup.string().trim().required('パスワードは必須項目です。')
})

const SciWsSettingRegister = ({ dialog, setDialog }) => {
  console.log('SciWsSettingRegister')
  const request = useApi()

  const form = useForm({
    defaultValues: {
      sciws_name: '',
      description: '',
      sciws_url: 'https://webra1.secomtrust.net/sciraws/services/ExternalFederation?wsdl',
      sciws_keystore_name: '',
      sciws_keystore_base64: '',
      sciws_password: ''
    },
    resolver: yupResolver(SCIWS_SETTING_FORM)
  })

  const onSubmit = async (values) => {
    console.log('onSubmit')
    await request.post('/sciws/wsSetting', values, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const close = {
    dialog: () => setDialog({ open: false }),
    form: form.reset
  }

  return (
    <Dialog title="SCI-WS接続設定の登録" open={dialog.open} close={close} maxWidth="lg">
      {/* <Loading name="load2" sx={{ top: '-34px' }} /> */}
      <ContentForm form={form} />
      {/* <Divider variant="middle" />
      <ContentForm request={request} state={state} setState={setObject} form={form} /> */}
      <Divider variant="middle" />
      <DialogActions>
        <Button
          onClick={() => {
            close.dialog()
            close.form()
          }}
        >
          キャンセル
        </Button>
        <LoadButton onClick={form.handleSubmit(onSubmit)}>送信</LoadButton>
      </DialogActions>
    </Dialog>
  )
}

SciWsSettingRegister.propTypes = {
  dialog: PropTypes.object,
  setDialog: PropTypes.func
}

const SciWsSettingEdit = ({ dialog, setDialog }) => {
  console.log('SciWsSettingEdit')
  const request = useApi()
  // const { state, setObject, reset } = useStateManage({ expanded: false, settings: [], ejbcas: [], cas: [], ee_profiles: [], available_cert_profiles: [] })
  const { state, setObject, reset } = useStateManage({ expanded: false, settings: [] })
  const form = useForm({
    defaultValues: {
      sciws_name: '',
      description: '',
      sciws_url: 'https://webra1.secomtrust.net/sciraws/services/ExternalFederation?wsdl',
      sciws_keystore_name: '',
      sciws_keystore_base64: '',
      sciws_password: ''
    }
    // resolver: yupResolver(SCIWS_SETTING_FORM)
  })

  const clickUpdate = async (values) => {
    await request.patch('/sciws/wsSetting', values, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const clickDelete = async (values) => {
    await request.delete('/sciws/wsSetting', values, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const close = {
    dialog: () => setDialog({ open: false }),
    form: () => {
      form.reset()
      reset()
    }
  }

  useEffect(() => {
    if (!dialog.open) return
    request.get('/sciws/wsSetting', null, ({ body }) => {
      setObject({ settings: [...body] })
    })
  }, [dialog.open])

  const handleSettingSelectChange = (e) => {
    const sciws_id = Number(e.target.value)
    const setting = state.settings.find((elem) => elem.sciws_id === sciws_id)
    form.reset()
    if (!setting) {
      setObject({ expanded: false })
      return
    }
    setObject({ expanded: true })

    form.setValue('sciws_id', sciws_id)
    form.setValue('sciws_name', setting.sciws_name)
    form.setValue('description', setting.description)
    form.setValue('sciws_url', setting.sciws_url)
    // form.setValue('sciws_keystore_name', setting.sciws_keystore_name)
  }

  return (
    <Dialog title="SCI-WS設定の変更・削除" open={dialog.open} close={close} maxWidth="lg">
      <Loading name="load2" sx={{ top: '-34px' }} />
      <DialogContent sx={{ p: 2 }}>
        <Grid item xs={12}>
          <TextField
            select
            form={form}
            name="sciws_id"
            label="SCI-WS設定の選択*"
            autoFocus
            onChange={handleSettingSelectChange}
          >
            <MenuItem key={0} value="">
              {'　'}
            </MenuItem>
            {state.settings.map((item) => (
              <MenuItem key={item.sciws_id} value={`${item.sciws_id}`}>
                {item.sciws_name} {item.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </DialogContent>
      <Divider variant="middle" />

      <Collapse in={state.expanded} timeout="auto" unmountOnExit>
        <ContentForm form={form} />
        <Divider variant="middle" />
      </Collapse>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={() => {
            close.dialog()
            close.form()
          }}
        >
          キャンセル
        </Button>
        <Box>
          <LoadButton type="submit" sx={{ mr: 1 }} onClick={form.handleSubmit(clickDelete)} disabled={!state.expanded}>
            削除
          </LoadButton>
          <LoadButton type="submit" onClick={form.handleSubmit(clickUpdate)} disabled={!state.expanded}>
            変更
          </LoadButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

SciWsSettingEdit.propTypes = {
  dialog: PropTypes.object,
  setDialog: PropTypes.func
}
