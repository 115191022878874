import React from 'react'
import { Container, Grid } from '@mui/material'
import CertSearch from 'src/contents/ejbcaData/certList/CertSearch'
import CertTable from 'src/contents/ejbcaData/certList/CertTable'
import { MainProvider } from 'src/util/'
import { MainContent } from 'src/components'

const CertList = () => {
  console.log('CertList')

  return (
    <MainContent title="CertList | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CertSearch />
            </Grid>
            <Grid item xs={12}>
              <CertTable />
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default CertList
