import { createContext, useContext, useState, useRef, useReducer } from 'react'
import PropTypes from 'prop-types'
import { isEqual, isEqualDiff } from 'src/util/commons'

const MainContext = createContext({})
export const MainProvider = ({ children }) => {
  const _mainRef = useRef({
    form: {},
    pageRequest: {
      btn: '',
      pageNo: undefined,
      values: {},
      orderBy: {}
    },
    unusedRequestValues: [],
    sort: { name: '', desc: false },
    searchCb: () => {
      console.error('search not initialized!')
    },
    searchChangedCb: () => {
      console.error('search not initialized!')
    },
    values: {}
  })
  const [mainState, setMainState] = useState({ pageRequest: {} })
  const value = {
    mainState,
    setMainState,
    _mainRef
  }

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>
}
MainProvider.propTypes = { children: PropTypes.any }

/**
 * Context内の共有参照へのメソッドを提供する
 */
export const useMainRef = () => {
  const ctxt = useContext(MainContext)

  const method = {
    get: (field) => {
      return ctxt._mainRef.current.values[field]
    },
    set: (field, value) => {
      ctxt._mainRef.current.values[field] = value
    },
    getValues: () => {
      return ctxt._mainRef.current.values
    },
    setValues: (values) => {
      ctxt._mainRef.current.values = values
    },

    /**
     * 値が変更されている場合、参照値を更新する。
     * 更新不要な場合、falseを返す。
     */
    changeValues: (values) => {
      if (isEqual(ctxt._mainRef.current.values, values)) return false
      ctxt._mainRef.current.values = { ...values }
      return true
    }
  }
  return method
}

/**
 * Context内の共有参照を利用したページ制御を提供する。
 * 検索フォームと結果テーブルを分け、フォーム側からの呼び出しを行う。
 * @returns method
 */
export const usePageControl = () => {
  const ctxt = useContext(MainContext)
  const ref = ctxt._mainRef.current
  // const sortRef = useRef({ name: '', desc: false })

  const checkFormSearchChenged = (pageRequest) => {
    if (pageRequest.btn === 'search') return false
    const currentVals = ref.form.getValues()
    return !isEqual(currentVals, pageRequest.values)
  }

  const method = {
    sort: ref.sort,

    setMainRefVal: (field, value) => {
      ref.values[field] = value
    },
    getMainRefVal: (field) => {
      return ref.values[field]
    },
    setAfterCall: (afterCb) => {
      ref.afterCb = afterCb
    },

    // 検索テーブル側の初期化で使用。
    initSearch: (searchCb, searchChangedCb) => {
      ref.searchCb = searchCb
      ref.searchChangedCb = searchChangedCb
    },

    // 検索のパラメータに使用しない値を、検索前に設定
    unusedRequestValue: (value) => {
      ref.unusedRequestValues.push(value)
    },

    // Form側の検索コールバック呼び出し
    setFormRequest: (form, _pageRequest) => {
      if (form) ref.form = form
      // searchボタンは値初期化、その他のボタンはマージ（前回の値を保持）
      const newRequest = _pageRequest.btn === 'search' ? _pageRequest : { ...ref.pageRequest, ..._pageRequest }
      ref.pageRequest = newRequest
      method.callSearch(newRequest, ref.searchCb, ref.searchChangedCb)
    },

    // 検索の呼び出し
    callSearch: async (pageRequest, searchCb, searchChangedCb) => {
      if (!pageRequest.btn) return
      if (checkFormSearchChenged(pageRequest)) {
        searchChangedCb()
        return
      }
      if (pageRequest.btn === 'search' && ref.sort.name !== '') {
        ref.sort = { name: '', desc: false }
      }
      const values = { ...pageRequest.values, ...{ orderBy: pageRequest.orderBy } }
      ref.unusedRequestValues.forEach((key) => delete values[key])
      const rv = await searchCb(pageRequest.pageNo, values)
      if (ref.afterCb) ref.afterCb(rv)
    },

    // ソート時のハンドル
    handleSort: (_sort, searchChangedCb) => {
      if (checkFormSearchChenged(ref.pageRequest)) {
        searchChangedCb()
        return
      }

      ref.sort = { ..._sort }
      let _order = _sort.name
      if (_sort.desc) _order += ' desc'
      method.setFormRequest(null, { btn: 'sort', pageNo: 1, orderBy: _order })
    }
  }
  return method
}
