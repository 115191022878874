import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { StyledCell } from './StyledCell'

const SortCell = ({ name, sort, firstSortDescending, onClick, sx, children, ...props }) => {
  const handlClick = (e) => {
    const attr = e.currentTarget.attributes
    const _name = attr.name && attr.name.value
    if (!_name) return

    const _first = e.currentTarget.dataset.firstSort
    let _desc = _first === '1'
    if (_name === sort.name) _desc = !sort.desc
    onClick({ name: _name, desc: _desc }, e)
  }

  return (
    <StyledCell
      sx={{ position: 'relative', cursor: 'pointer', '&:hover': { opacity: 0.87 }, ...sx }}
      name={name}
      onClick={handlClick}
      data-first-sort={firstSortDescending ? 1 : 0}
      {...props}
    >
      {children}
      {sort.name === name &&
        (sort.desc ? (
          <KeyboardArrowDown viewBox="6 8 30 30" sx={{ position: 'absolute', top: '1px', left: '50%' }} />
        ) : (
          <KeyboardArrowUp viewBox="6 8 30 30" sx={{ position: 'absolute', top: '1px', left: '50%' }} />
        ))}
    </StyledCell>
  )
}

SortCell.propTypes = {
  name: PropTypes.string.isRequired,
  sort: PropTypes.object,
  firstSortDescending: PropTypes.bool,
  onClick: PropTypes.func,
  sx: PropTypes.object,
  children: PropTypes.any
}

export default SortCell
