import React, { useState } from 'react'
import { Container, Grid } from '@mui/material'
import StatusRate from 'src/contents/urlMonitor/StatusRate'
import { AggregateSearch, initialState } from 'src/contents/urlMonitor/AggregateSearch'
import CheckedDataList from 'src/contents/urlMonitor/CheckedDataList'
import { useApi, isEqual } from 'src/util/'
import { Dialog, MainContent } from 'src/components'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
// import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const UrlMonitor = () => {
  console.log('UrlMonitor')
  const request = useApi()
  const [response, setResponse] = useState()
  const [no, setPage] = useState(1)
  const [form, setForm] = useState(initialState())
  const [prevForm, setPrevForm] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)

  const setFormElements = (elements) => {
    setForm({ ...form, ...elements })
  }

  const handleClickAggregate = (pageNo) => {
    setPage(pageNo)
    setPrevForm(form)
    request.get('/moni/aggregate/page', { ...form, pageNo }, ({ body }) => {
      setResponse(body)
    })
  }

  const handleClickPage = (pageNo) => {
    if (pageNo === no) return

    if (isEqual(form, prevForm)) {
      handleClickAggregate(pageNo)
    } else {
      setDialogOpen(true)
    }
  }

  return (
    <MainContent title="UrlMonitor | M System">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={8} xl={9}>
            <AggregateSearch form={form} setFormElem={setFormElements} onClickAggregate={handleClickAggregate} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={3}>
            <StatusRate response={response} />
          </Grid>
          <Grid item xs={12}>
            <CheckedDataList response={response} onClickPage={handleClickPage} pageNo={no} />
          </Grid>
        </Grid>
        <Dialog title="集計条件の変更" open={dialogOpen} close={() => setDialogOpen(false)}>
          集計条件が変更されています。 再度「集計」を実施した後に、ページ番号を選択ください。
        </Dialog>
      </Container>
    </MainContent>
  )
}

export default UrlMonitor
