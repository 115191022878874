import { useEffect, useState } from 'react'
import { Card, Table, TableContainer, TableHead, TableRow, IconButton } from '@mui/material'
import { Refresh as RefreshIcon } from '@mui/icons-material'
import { HeaderBox, StyledCell, SortCellLocal } from 'src/components'
import { useApi, useStateManage } from 'src/util/'
import BatchTableBody from './BatchTableBody'

const BatchTable = () => {
  console.log('BatchTable')
  const request = useApi()
  const { state, setObject } = useStateManage({ dataTotal: 0, pageTotal: 0, pageNo: 0, list: [], dialog: false })
  const [sort, setSort] = useState({ name: '', desc: false })

  const search = () => {
    request.get('/bat/list', null, ({ body }) => {
      setObject({ list: [...body] })
    })
  }
  useEffect(search, [])

  return (
    <Card>
      <HeaderBox
        title={
          <>
            バッチ一覧
            <IconButton sx={{ m: '-8px', marginLeft: '4px' }} onClick={search}>
              <RefreshIcon />
            </IconButton>
          </>
        }
      >
        {state.dataTotal !== 0 && '計' + state.dataTotal + '件'}
      </HeaderBox>
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <SortCellLocal sx={{ minWidth: 70 }} name="batch_id" sort={sort} setSort={setSort}>
                バッチID
              </SortCellLocal>
              <SortCellLocal sx={{ minWidth: 180 }} name="batch_file_name" sort={sort} setSort={setSort}>
                バッチ名
              </SortCellLocal>
              <SortCellLocal sx={{ minWidth: 130 }} name="cron_formula" sort={sort} setSort={setSort}>
                実行スケジュール
              </SortCellLocal>
              <SortCellLocal sx={{ minWidth: 90 }} name="status" sort={sort} setSort={setSort}>
                ステータス
              </SortCellLocal>
              <SortCellLocal sx={{ minWidth: 120 }} name="ended_at" sort={sort} setSort={setSort}>
                終了時間
              </SortCellLocal>
              <SortCellLocal sx={{ minWidth: 90 }} name="exit_cd" sort={sort} setSort={setSort}>
                終了コード
              </SortCellLocal>
              <SortCellLocal sx={{ minWidth: 340 }} name="result" sort={sort} setSort={setSort}>
                終了結果
              </SortCellLocal>
              <SortCellLocal sx={{ minWidth: 90 }} name="next_batch_id" sort={sort} setSort={setSort}>
                後続バッチID
              </SortCellLocal>
              <StyledCell sx={{ width: 24 }} />
            </TableRow>
          </TableHead>
          <BatchTableBody list={state.list} />
        </Table>
      </TableContainer>
    </Card>
  )
}

export default BatchTable
