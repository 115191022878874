import { Box, Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TextField, HeaderBox } from 'src/components'
import { usePageControl } from 'src/util/'
import ContractSearchMore from './ContractSearchMore'

export const ContractSearch = () => {
  console.log('ContractSearch')
  const pc = usePageControl()

  const form = useForm({
    defaultValues: { contract_name: '', contract_user_prefix: '' }
  })

  const onSubmit = (values) => {
    pc.setFormRequest(form, { btn: 'search', pageNo: 1, values })
  }

  useEffect(() => {
    form.handleSubmit(onSubmit)()
  }, [])

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <HeaderBox title="契約検索">
          <ContractSearchMore />
        </HeaderBox>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField form={form} name="contract_name" label="契約名（前方一致）" />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField form={form} name="contract_user_prefix" label="プレフィックス（前方一致）" />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 1, px: 2 }}>
          <Button type="submit" color="primary" variant="contained" onClick={form.handleSubmit(onSubmit)}>
            検索
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default ContractSearch
