import React from 'react'
import { Container, Grid } from '@mui/material'
import HistorySearch from 'src/contents/ejbcaLogHistory/HistorySearch'
import HistoryTable from 'src/contents/ejbcaLogHistory/HistoryTable'
import { MainProvider } from 'src/util/'
import { MainContent } from 'src/components'

const EjbcaLogHistory = () => {
  console.log('EjbcaLogHistory')

  return (
    <MainContent title="EjbcaLogHistory | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <HistorySearch />
            </Grid>
            <Grid item xs={12}>
              <HistoryTable />
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default EjbcaLogHistory
