import { Box, Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { HeaderBox, TextField } from 'src/components'
import { usePageControl } from 'src/util/'
import SciWsSearchMore from './SciWsSetting'
import SciWsRequest from './SciWsRequest'

export const SciRaWsSearch = () => {
  console.log('SciRaWsSearch')
  const pc = usePageControl()
  const [dialog, setDialog] = useState({ open: false })

  const form = useForm({
    defaultValues: { sciws_name: '', method: '', respons_status_cd: '' }
  })
  const onSubmit = (values) => {
    pc.setFormRequest(form, { btn: 'search', pageNo: 1, values })
  }

  useEffect(() => {
    form.handleSubmit(onSubmit)()
  }, [])

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <HeaderBox title="SCI-WS 履歴検索">
          <SciWsSearchMore />
        </HeaderBox>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="sciws_name" label="SCI-WS名" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="method" label="メソッド名" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="respons_status_cd" label="ステータスCD" />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1, px: 2 }}>
          <SciWsRequest dialog={dialog} setDialog={setDialog} searchVals={form.getValues()} />
          <Button type="button" color="primary" variant="contained" onClick={() => setDialog({ open: true })}>
            WSリクエスト
          </Button>
          <Button type="submit" color="primary" variant="contained">
            検索
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default SciRaWsSearch
