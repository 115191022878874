import { NavLink, matchPath, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { List, ListItemButton, ListItemText, Collapse } from '@mui/material'
import { ArrowRight, ArrowDropDown, FiberManualRecord as ChildIcon } from '@mui/icons-material'

import { styled } from '@mui/material/styles'
import { useState, useEffect } from 'react'

const RouterLink = styled(NavLink)({ minWidth: 40 })

const RouterLinkButton = ({ to = '', children, ...rest }) => {
  if (to === '') return <ListItemButton {...rest}>{children}</ListItemButton>
  return (
    <ListItemButton component={RouterLink} to={to} {...rest}>
      {children}
    </ListItemButton>
  )
}

RouterLinkButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any
}

const SidebarListItem = ({ item, icon: Icon, iconSize, sidebarOpen, paddingLeft = 0, ...rest }) => {
  console.log('SidebarListItem')
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    if (!sidebarOpen && !open && item.children) {
      document.getElementById('sidebar-open-button').click()
    }
    setOpen(!open)
  }

  const active = item.path ? matchPath({ path: item.path, end: false }, location.pathname) : false

  useEffect(() => {
    if (!sidebarOpen) setOpen(false)
    else if (item.loginOpen) {
      item.loginOpen = false
      setOpen(true)
    }
  }, [sidebarOpen])

  return (
    <>
      <RouterLinkButton
        to={item.path}
        onClick={handleOpen}
        // sx={{ minHeight: '48px', display: 'flex', justifyContent: 'center' }}
        sx={{ minHeight: '44px', py: 0, pl: paddingLeft }}
      >
        {/* <ListItemIcon height="20" width="20" sx={{ width: '10px' }}> */}
        {Icon && (
          <Icon
            sx={{
              ...(iconSize && { height: iconSize }),
              ...(active ? { color: 'primary.main' } : { color: 'rgba(0, 0, 0, 0.54)' })
            }}
          />
        )}
        {/* </ListItemIcon> */}
        <ListItemText
          primaryTypographyProps={{
            fontSize: '0.875rem',
            ...(active ? { color: 'primary.main' } : { color: 'rgba(0, 0, 0, 0.54)' }),
            pl: 1
          }}
          primary={sidebarOpen && item.title}
        />

        {item.children &&
          (open ? (
            <ArrowDropDown sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
          ) : (
            <ArrowRight sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
          ))}
      </RouterLinkButton>
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ p: 0 }}>
            {item.children.map((_item) => (
              <SidebarListItem
                key={_item.app_menu_id + _item.title}
                item={_item}
                icon={ChildIcon}
                iconSize="12px"
                sidebarOpen={sidebarOpen}
                paddingLeft={paddingLeft + 1.5}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

SidebarListItem.propTypes = {
  item: PropTypes.object,
  icon: PropTypes.elementType,
  iconSize: PropTypes.string,
  sidebarOpen: PropTypes.bool,
  paddingLeft: PropTypes.number
}

export default SidebarListItem
