import { Button, Card, Checkbox, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect, useState } from 'react'
import { Dialog, StyledCell, TextAreaCell, HeaderBox, FooterBox } from 'src/components'
import { useApi, diffForm, createDiffParams } from 'src/util/'
import UrlMoniSettingsMore from './UrlMoniSettingsMore'

const UrlMoniSettings = () => {
  console.log('UrlMoniSettings')
  const request = useApi()
  const [response, setResponse] = useState({})
  const [dialog, setDialog] = useState(false)
  const [form, setForm] = useState([])

  const initTable = () => {
    request.get('/moni/mstUrl/list', null, ({ body }) => {
      setForm([...body])
      setResponse(body)
    })
  }
  useEffect(initTable, [])

  const handleDiffForm = (id, name, value) => setForm(diffForm(id, name, value, response, form))
  const handleDiffChange = (e) => handleDiffForm(e.target.id, e.target.name, e.target.value)
  const handleDiffCheckbox = (e) => handleDiffForm(e.target.id, e.target.name, e.target.checked)

  const submitUpdate = (e) => {
    const reqestParams = createDiffParams(e, form, ['url_id'], ['description', 'check_target_flg', 'public_flg'])
    if (!reqestParams.length) return
    request.put('/moni/mstUrl/bulk', reqestParams, () => {
      setDialog(true)
      initTable()
    })
  }

  return (
    <Card>
      <HeaderBox title="監視設定">
        <UrlMoniSettingsMore init={initTable} form={form} />
      </HeaderBox>
      <TableContainer>
        {/* <PerfectScrollbar> */}
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <StyledCell>No</StyledCell>
              <StyledCell sx={{ minWidth: 300 }}>URL</StyledCell>
              <StyledCell sx={{ minWidth: 300 }}>説明</StyledCell>
              <StyledCell align="center">監視対象</StyledCell>
              <StyledCell align="center">公開対象</StyledCell>
              <StyledCell sx={{ minWidth: 110, width: 110 }}>更新日</StyledCell>
              <StyledCell>更新者</StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {form &&
              form.map((row, index) => (
                <TableRow hover key={row.no}>
                  <StyledCell>{row.no}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.url}</StyledCell>
                  <TextAreaCell
                    id={index + ''}
                    name="description"
                    onBlur={handleDiffChange}
                    value={response[index] && response[index].description}
                  />
                  <StyledCell align="center" turnOnBgColor={row.check_target_flg_diff} sx={{ p: '0' }}>
                    <Checkbox
                      id={index + ''}
                      name="check_target_flg"
                      checked={row.check_target_flg}
                      value={row.check_target_flg}
                      color="secondary"
                      onChange={handleDiffCheckbox}
                    />
                  </StyledCell>
                  <StyledCell align="center" turnOnBgColor={row.public_flg_diff} sx={{ p: '0' }}>
                    <Checkbox
                      id={index + ''}
                      name="public_flg"
                      checked={row.public_flg}
                      color="secondary"
                      onChange={handleDiffCheckbox}
                    />
                  </StyledCell>
                  <StyledCell>{row.updated_at}</StyledCell>
                  <StyledCell>{row.updater}</StyledCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {/* </PerfectScrollbar> */}
      </TableContainer>
      <FooterBox>
        <Button type="submit" onClick={submitUpdate} variant="contained">
          更新
        </Button>
        <Dialog title="URL監視対象の更新" open={dialog} close={() => setDialog(false)}>
          URL監視対象が更新されました。
        </Dialog>
      </FooterBox>
    </Card>
  )
}

export default UrlMoniSettings
