import { Container } from '@mui/material'
import UrlMoniSettings from 'src/contents/urlMonitor/UrlMoniSettings'
import { MainContent } from 'src/components'

const UrlMonitorSetting = () => {
  // console.log('UrlMonitorSetting: ')

  return (
    <MainContent title="Monitor Setting | M System">
      <Container maxWidth="false">
        <UrlMoniSettings />
      </Container>
    </MainContent>
  )
}

export default UrlMonitorSetting
