import React from 'react'
import { styled } from '@mui/material/styles'
import { TableCell, tableCellClasses, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const StyledTableCell = styled(TableCell, { shouldForwardProp: (prop) => prop !== 'focusColorOn' })(
  ({ theme, focusColorOn }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      paddingLeft: '8px',
      paddingRight: '8px',
      '&:first-of-type': { paddingLeft: '16px' },
      '&:last-child': { paddingRight: '16px' },
      borderTop: '1px solid',
      borderTopColor: theme.palette.grey[300]
    },
    [`&.${tableCellClasses.body}`]: {
      paddingLeft: '8px',
      paddingRight: '8px',
      '&:first-of-type': { paddingLeft: '16px' },
      '&:last-child': { paddingRight: '16px' },
      ...(focusColorOn && {
        '&:hover': { backgroundColor: '#e8f0fe' },
        cursor: 'pointer'
      })
    }
  })
)

export const StyledCell = ({ turnOnBgColor, sx, children, ...props }) => {
  if (turnOnBgColor) {
    if (!sx) sx = {}
    sx.backgroundColor = '#fdeded'
  }
  return (
    <StyledTableCell sx={sx} {...props}>
      {children}
    </StyledTableCell>
  )
}

StyledCell.propTypes = {
  turnOnBgColor: PropTypes.bool,
  backgroundColor: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.any
}

export const StyledLinkCell = ({ to, children, ...props }) => {
  return (
    <StyledCell {...props}>
      <Link component={RouterLink} to={to} variant="h6" underline="hover">
        {children}
      </Link>
    </StyledCell>
  )
}

StyledLinkCell.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any
}
