import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useApi } from 'src/util/'
import { Dialog, TextPassword, HeaderBox, FooterBox } from 'src/components'
import SettingsPasswordMore from './SettingsPasswordMore'

const SETTING_PASSWORD = yup.object({
  current_password: yup.string().required('現在のパスワードは必須項目です。'),
  new_password: yup
    .string()
    .required('新しいパスワードは必須項目です。')
    .matches(
      /^(?=.*?[a-zA-Z])(?=.*[0-9])(?=.*[#$%&@*+\-_])[a-zA-Z0-9#$%&@*+\-_]{8,32}$/,
      'パスワードは8～32文字で半角英数字と記号を含めてください。'
    )
    .notOneOf([yup.ref('current_password')], '現在のパスワードと同じパスワードは使用できません。'),
  confirm: yup
    .string()
    .required('新しいパスワードの確認は必須項目です。')
    .oneOf([yup.ref('new_password')], '新しいパスワードと一致しません。')
})

const SettingsPassword = () => {
  console.log('SettingsPassword')
  const [dialog, setDialog] = useState(false)
  const request = useApi()

  const form = useForm({
    defaultValues: { current_password: '', new_password: '', confirm: '' },
    resolver: yupResolver(SETTING_PASSWORD)
  })

  const onSubmit = (values) => {
    request.put('/myPassword', values, ({ body }) => {
      if (body.success) {
        form.reset()
        setDialog(true)
      }
    })
  }

  return (
    <form autoComplete="off" onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
        <HeaderBox title="パスワードの変更">
          <SettingsPasswordMore />
        </HeaderBox>
        <Divider />
        <CardContent sx={{ flex: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextPassword form={form} name="current_password" label="現在のパスワード*" />
            </Grid>
            <Grid item xs={12}>
              <TextPassword form={form} name="new_password" label="新しいパスワード*" />
            </Grid>
            <Grid item xs={12}>
              <TextPassword form={form} name="confirm" label="新しいパスワードの確認*" />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <FooterBox>
          <Button type="submit" color="primary" variant="contained">
            更新
          </Button>
          <Dialog title="パスワードの変更" open={dialog} close={() => setDialog(false)}>
            新しいパスワードに変更されました。
          </Dialog>
        </FooterBox>
      </Card>
    </form>
  )
}

export default SettingsPassword
