import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
  TextField
  // textFieldClasses
} from '@mui/material'
// import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import PropTypes from 'prop-types'
import { StyledCell } from './StyledCell'

const StyledTextField = styled(TextField)({
  '& .MuiInput-underline': {
    padding: 0,
    '&::before': { border: 'none' }
  }
})

/**
 * ボーダーを持たないテキストエリア
 *
 * onChangeの値反映を自身のStateで管理することで、レンダリングの遅延を回避している。
 * 親Stateへの反映はonBlur等で行う必要がある。
 */
const TextAreaCell = ({ ...props }) => {
  // console.log('StyledTextArea')
  const parentValue = props.value
  const [holdVal, setHoldVal] = useState(parentValue)
  const [value, setValue] = useState(parentValue)
  const [isDiff, setDiff] = useState(false)

  // 親React要素の値更新時（API通信後）
  if (holdVal !== parentValue) {
    setHoldVal(parentValue)
    setValue(parentValue)
    setDiff(false)
  }

  // 入力時
  const _setValue = (e) => {
    setValue(e.target.value)
    setDiff(parentValue !== e.target.value)
  }

  return (
    <StyledCell turnOnBgColor={isDiff} sx={{ py: 0, wordBreak: 'break-all' }}>
      <StyledTextField
        {...props}
        variant="standard"
        size="small"
        fullWidth
        multiline
        maxRows={2}
        onChange={_setValue}
        // onBlur={onBlur}
        value={value}
        inputProps={{ style: { fontSize: 14 } }}
        // InputLabelProps={{ style: { fontSize: 14, lineHeight: 'normal', height: '100%' } }}
      />
    </StyledCell>
  )
}

TextAreaCell.propTypes = {
  value: PropTypes.string,
  initFlag: PropTypes.bool
}
export default TextAreaCell
