import { Container, Grid } from '@mui/material'
import { useState, useEffect } from 'react'
import ContractTable from 'src/contents/contractManage/ContractTable'
import ContractSearch from 'src/contents/contractManage/ContractSearch'
import { useApi, MainProvider } from 'src/util/'
import { MainContent } from 'src/components'

const ContractManage = () => {
  console.log('ContractManage')

  return (
    <MainContent title="ContractManage | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ContractSearch />
            </Grid>
            <Grid item xs={12}>
              <ContractTable />
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default ContractManage
