import { Collapse, Alert as AlertTag, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'

export const Alert = ({ children, open, onClose, severity, ...props }) => {
  const [init, setInit] = useState(false)
  useEffect(() => setInit(true), [])

  return (
    <Collapse in={init && open}>
      <AlertTag
        severity={severity}
        {...props}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={() => onClose()}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {children}
      </AlertTag>
    </Collapse>
  )
}

Alert.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  severity: PropTypes.string
}
