import { Box, Button, Card, CardContent, Divider, Grid } from '@mui/material'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { AutocompleteText, DownloadButton, HeaderBox, TextField } from 'src/components'
// import { AutocompleteText2 } from 'src/components/form/AutocompleteText'
import { usePageControl } from 'src/util/'

export const HistorySearch = () => {
  console.log('HistorySearch')
  const pc = usePageControl()

  const form = useForm({
    defaultValues: { remote_name: '', host_name: '', ca_name: '', timeStamp: '', eventStatus: '', eventType: '' }
  })
  const onSubmit = (values) => pc.setFormRequest(form, { btn: 'search', pageNo: 1, values })

  useEffect(() => {
    form.setValue('timeStamp', dayjs().add(-3, 'days').format('YYYY-MM-DD'))
    form.handleSubmit(onSubmit)()
  }, [])

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <HeaderBox title="EJBCAログ履歴 検索" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="remote_name" label="リモート名（前方一致）" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="host_name" label="ホスト名（前方一致）" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="ca_name" label="CA名（前方一致）" inputProps={{ maxLength: 64 }} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                form={form}
                name="timeStamp"
                label="タイムスタンプ（以降）"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AutocompleteText
                setValue={form.setValue}
                name="eventStatus"
                label="イベントステータス"
                reqParams={{
                  method: 'get',
                  path: '/selectGroup',
                  sendData: { selectName: 'ejbcas.log_inspection.eventStatus' }
                }}
                optionPropName="value"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AutocompleteText
                setValue={form.setValue}
                name="eventType"
                label="イベントタイプ"
                reqParams={{
                  method: 'get',
                  path: '/selectGroup',
                  sendData: { selectName: 'ejbcas.log_inspection.eventType' }
                }}
                optionPropName="value"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 1, px: 2 }}>
          <DownloadButton
            fileName={dayjs().format('YYYYMMDD_HHmmss') + '.csv'}
            label="CSVダウンロード"
            reqParams={{ method: 'get', path: '/ejbcas/logHistory/csv', sendData: form.getValues() }}
            afterClick={form.handleSubmit(onSubmit)}
          />
          <DownloadButton
            fileName={dayjs().format('YYYYMMDD_HHmmss') + '.zip'}
            label="ログ検査シート用(ZIP)"
            reqParams={{ method: 'get', path: '/ejbcas/logHistory/zip', sendData: form.getValues() }}
            afterClick={form.handleSubmit(onSubmit)}
          />
          <Button type="submit" color="primary" variant="contained">
            検索
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default HistorySearch
