import React from 'react'
import { Container, Grid } from '@mui/material'
import SciWsSearch from 'src/contents/sciWs/SciWsSearch'
import SciWsTable from 'src/contents/sciWs/SciWsTable'
import { MainProvider } from 'src/util/'
import { MainContent } from 'src/components'

const SciWs = () => {
  console.log('SciWs')

  return (
    <MainContent title="SciWs | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SciWsSearch />
            </Grid>
            <Grid item xs={12}>
              <SciWsTable />
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default SciWs
