import { Box, Button, Card, CardContent, Divider, Grid, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TextField, HeaderBox } from 'src/components'
import { usePageControl } from 'src/util/'
import UserSearchMore from './UserSearchMore'

const GridTemplate = styled(Box)(({ theme, xs, sm, md, lg, xl }) => ({
  display: 'grid',
  // gridTemplateColumns: template,
  gridGap: '16px',
  // margin: 'auto',
  [theme.breakpoints.up('xs')]: xs && {
    gridTemplateColumns: xs
  },
  [theme.breakpoints.up('sm')]: sm && {
    gridTemplateColumns: sm
  },
  [theme.breakpoints.up('md')]: md && {
    gridTemplateColumns: md
  },
  [theme.breakpoints.up('lg')]: lg && {
    gridTemplateColumns: lg
  },
  [theme.breakpoints.up('xl')]: xl && {
    gridTemplateColumns: xl
  }
}))

export const UserSearch = ({ authGroupList }) => {
  console.log('UserSearch')
  const pc = usePageControl()

  const form = useForm({
    defaultValues: { user_id: '', user_name: '', mail: '', auth_group_name: '', is_locked: '' }
  })

  const onSubmit = (values) => {
    pc.setFormRequest(form, { btn: 'search', pageNo: 1, values })
  }

  useEffect(() => {
    form.handleSubmit(onSubmit)()
  }, [])

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <HeaderBox title="ユーザ検索">
          <UserSearchMore authGroupList={authGroupList} />
        </HeaderBox>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField form={form} name="user_id" label="ID（前方一致）" />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField form={form} name="user_name" label="名前（前方一致）" />
            </Grid>
          </Grid>

          <GridTemplate sx={{ mt: 2 }} xs="1fr" sm="1fr 1fr" md="1fr 1fr 120px ">
            <TextField form={form} name="mail" label="メール（前方一致）" inputProps={{ maxLength: 64 }} />
            <TextField
              form={form}
              name="auth_group_name"
              label="権限グループ（前方一致）"
              inputProps={{ maxLength: 64 }}
            />
            <TextField select form={form} name="is_locked" label="ロック">
              <MenuItem key={0} value="">
                すべて
              </MenuItem>
              <MenuItem key={1} value="1">
                有
              </MenuItem>
              <MenuItem key={2} value="0">
                無
              </MenuItem>
            </TextField>
          </GridTemplate>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 1, px: 2 }}>
          <Button type="submit" color="primary" variant="contained" onClick={form.handleSubmit(onSubmit)}>
            検索
          </Button>
        </Box>
      </Card>
    </form>
  )
}

UserSearch.propTypes = {
  authGroupList: PropTypes.array
}

export default UserSearch
