import { Box, Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { HeaderBox, TextField } from 'src/components'
import { useApi, usePageControl } from 'src/util/'

export const OrderSearch = () => {
  console.log('OrderSearch')
  const [global, render] = useOutletContext()
  const request = useApi()
  const pc = usePageControl()

  const form = useForm({
    defaultValues: { account_id: '', status: '', expires: '', issued_subject_dn: '', identifier_value: '' }
  })

  const onSubmit = (values) => {
    pc.unusedRequestValue('title')
    pc.setFormRequest(form, { btn: 'search', pageNo: 1, values })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(decodeURI(window.location.search))
    const account_id = searchParams.get('account_id')
    form.setValue('account_id', account_id || '')
    form.handleSubmit(onSubmit)()

    // request.get('/acme/order/1', values, ({ body }) => {
    //   const row = body.rows[0]
    //   if (!row) return

    //   form.setValue('ejbca_id', row.ejbca_id)
    //   form.setValue('title', `${row.remote_name} / ${row.host_name}`)
    //   if (values.ca_id) form.setValue('ca_name', row.ca_name)
    //   form.handleSubmit(onSubmit)()
    // })
  }, [])

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <HeaderBox title="オーダー 検索" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="account_id" label="アカウントID" inputProps={{ maxLength: 32 }} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                form={form}
                name="expires"
                label="注文期限（以降）"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="status" label="ステータス" inputProps={{ maxLength: 16 }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField form={form} name="issued_subject_dn" label="サブジェクトDN" inputProps={{ maxLength: 400 }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField form={form} name="identifier_value" label="ドメイン" inputProps={{ maxLength: 64 }} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 1, px: 2 }}>
          <Button type="submit" color="primary" variant="contained">
            検索
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default OrderSearch
