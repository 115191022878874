import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

export const FooterBox = ({ title, sx, children, ...props }) => {
  return (
    <Box
      py={1}
      px={2}
      sx={{ minHeight: '48px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ...sx }}
      {...props}
    >
      {title && <Typography variant="h5">{title}</Typography>}
      {children}
    </Box>
  )
}

FooterBox.propTypes = {
  title: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.any
}
