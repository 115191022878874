import {
  Button,
  Card,
  Divider,
  DialogContent,
  MenuItem,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer
} from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { memo, useState, useEffect } from 'react'
import { Dialog, LoadButton, HeaderBox, MoreIconButton, StyledCell, TextField, RenderingSuppress } from 'src/components'
import { useApi, useStateManage } from 'src/util/'

const BAT_TARGET = yup.object({
  remote_id: yup.string().required('リモートサーバは必須項目です。')
})

const DialogBatHistoryTarget = ({ open, onClose }) => {
  console.log('DialogBatHistoryTarget')
  const request = useApi()
  const [state, setState] = useState({ list: [], batTargets: [] })

  const init = () => {
    request.get('/rmt/loginHistoryBatch', null, (resp1) => {
      request.get('/rmt/sshConnection/list', null, (resp2) => {
        setState({ list: [...resp2.body], batTargets: [...resp1.body] })
      })
    })
  }
  useEffect(init, [])

  const form = useForm({
    defaultValues: { remote_id: '' },
    resolver: yupResolver(BAT_TARGET)
  })

  const onSubmit = (values) => {
    request.post('/rmt/loginHistoryBatch', values, () => {
      init()
    })
  }

  const handleDelete = (remote_id) => {
    request.delete('/rmt/loginHistoryBatch', { remote_id }, () => {
      init()
    })
  }

  return (
    <Dialog
      title="リモートログイン履歴 バッチ対象"
      open={open}
      maxWidth="lg"
      close={{ dialog: onClose, form: form.reset }}
    >
      <DialogContent sx={{ overflow: 'hidden', p: 2 }}>
        {/* <DialogContentText>
          ログイン履歴バッチの対象に追加するリモート先（LinuxOS）を選択してください。
        </DialogContentText> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField select form={form} name="remote_id" label="リモート接続先" size="small">
              <MenuItem key={0} value="">
                {'　'}
              </MenuItem>
              {state.list.map((item) => (
                <MenuItem key={item.remote_id} value={item.remote_id}>
                  {item.remote_name + '（' + item.host + ':' + item.port + '）'}
                  {item.description && item.description}
                </MenuItem>
              ))}
            </TextField>
            <LoadButton
              onClick={form.handleSubmit(onSubmit)}
              type="submit"
              color="primary"
              variant="contained"
              sx={{ height: '40px', ml: 2, wordBreak: 'keep-all' }}
            >
              対象に追加
            </LoadButton>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogContent sx={{ overflow: 'hidden', px: 2, pt: 0, pb: 2 }}>
        <HeaderBox title="バッチ対象一覧">
          {state.batTargets.length !== 0 && `計${state.batTargets.length}件`}
        </HeaderBox>
        <Card>
          <TableContainer>
            <Table sx={{ borderCollapse: 'separate' }} size="small">
              <TableHead sx={{ wordBreak: 'keep-all' }}>
                <TableRow>
                  <StyledCell style={{ minWidth: 160, borderTop: 'none' }}>リモート名</StyledCell>
                  <StyledCell style={{ minWidth: 120, borderTop: 'none' }}>アドレス</StyledCell>
                  <StyledCell style={{ minWidth: 120, borderTop: 'none' }}>ホスト名</StyledCell>
                  <StyledCell style={{ minWidth: 240, borderTop: 'none' }}>OS</StyledCell>
                  <StyledCell style={{ width: 36, borderTop: 'none' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {state.batTargets &&
                  state.batTargets.map((row, index) => (
                    <TableRow hover key={row.remote_id}>
                      <StyledCell sx={{ wordBreak: 'break-all' }}>{row.remote_name}</StyledCell>
                      <StyledCell sx={{ wordBreak: 'break-all' }}>
                        {row.host}:{row.port}
                      </StyledCell>
                      <StyledCell sx={{ wordBreak: 'break-all' }}>{row.host_name}</StyledCell>
                      <StyledCell sx={{ wordBreak: 'break-all' }}>{row.edition}</StyledCell>
                      <StyledCell>
                        <Button
                          type="submit"
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={() => handleDelete(row.remote_id)}
                        >
                          削除
                        </Button>
                      </StyledCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </DialogContent>
    </Dialog>
  )
}

DialogBatHistoryTarget.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

const HistorySearchMore = memo(() => {
  console.log('HistorySearchMore')
  const { state, setValue } = useStateManage({ 1: false })
  const itemList = [{ itemName: 'バッチ対象', call: () => setValue(1, true) }]

  return (
    <>
      <MoreIconButton itemList={itemList} sx={{ my: '-8px' }} />
      <RenderingSuppress show={state[1]}>
        <DialogBatHistoryTarget open={state[1]} onClose={() => setValue(1, false)} />
      </RenderingSuppress>
    </>
  )
})

export default HistorySearchMore
