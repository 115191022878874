import React from 'react'
import { Container, Grid } from '@mui/material'
import EjbcaSearch from 'src/contents/ejbcaData/EjbcaSearch'
import EjbcaTable from 'src/contents/ejbcaData/EjbcaTable'
import { MainProvider } from 'src/util/'
import { MainContent } from 'src/components'

const EjbcaData = () => {
  console.log('EjbcaData')

  return (
    <MainContent title="EjbcaData | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <EjbcaSearch />
            </Grid>
            <Grid item xs={12}>
              <EjbcaTable />
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default EjbcaData
