import { Box, Button, Card, CardContent, Divider, Grid } from '@mui/material'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { DownloadButton, HeaderBox, TextField } from 'src/components'
import { usePageControl } from 'src/util/'
import HistorySearchMore from './HistorySearchMore'

export const HistorySearch = () => {
  const pc = usePageControl()

  const form = useForm({
    defaultValues: { remote_name: '', host_name: '', user: '', login_time: '', original_history: '' }
  })
  const onSubmit = (values) => {
    pc.setFormRequest(form, { btn: 'search', pageNo: 1, values })
  }

  useEffect(() => {
    form.setValue('login_time', dayjs().add(-3, 'days').format('YYYY-MM-DD'))
    form.handleSubmit(onSubmit)()
  }, [])

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <HeaderBox title="リモートログイン履歴 検索">
          <HistorySearchMore />
        </HeaderBox>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="remote_name" label="リモート名（前方一致）" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="host_name" label="ホスト名（前方一致）" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="user" label="ユーザ（前方一致）" inputProps={{ maxLength: 64 }} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                form={form}
                name="login_time"
                label="ログイン時刻（以降）"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField form={form} name="original_history" label="履歴（部分一致）" inputProps={{ maxLength: 64 }} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 1, px: 2 }}>
          <DownloadButton
            fileName={dayjs().format('YYYYMMDD_HHmmss') + '.csv'}
            label="CSVダウンロード"
            reqParams={{ method: 'get', path: '/rmt/loginHistory/csv', sendData: form.getValues() }}
            afterClick={form.handleSubmit(onSubmit)}
          />
          <Button type="submit" color="primary" variant="contained">
            検索
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default HistorySearch
