import { Autocomplete, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import { useState, useReducer } from 'react'
import { Controller } from 'react-hook-form'
import { useApi } from 'src/util/'
import { StyledTextField, TextField } from './StyledTextField'

const _set = (state, obj) => {
  return { ...state, ...obj }
}
export const AutocompleteText = ({ name, setValue, reqParams, optionPropName, ...props }) => {
  const [state, setState] = useReducer(_set, { init: true, loading: false, options: [] })
  const [value, setInputValue] = useState('')
  const request = useApi()

  if (!optionPropName) optionPropName = name

  const _setOepn = () => {
    if (!state.init) return
    setState({ init: false, loading: true })
    request[reqParams.method](
      reqParams.path,
      reqParams.sendData,
      ({ body }) => {
        setState({ options: [...body], loading: false })
      },
      () => {
        setState({ loading: false })
      }
    )
  }

  return (
    <Autocomplete
      freeSolo
      // autoSelect ※タブフォーカスで不具合あり
      onOpen={_setOepn}
      inputValue={value}
      onInputChange={(e, newValue) => {
        setValue(name, newValue)
        setInputValue(newValue)
      }}
      options={state.options.map((option) => option[optionPropName])}
      sx={{ '& .MuiOutlinedInput-root': { paddingTop: '5px', paddingBottom: '5px' } }}
      renderInput={(params) => (
        <StyledTextField
          {...props}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}
AutocompleteText.propTypes = {
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  reqParams: PropTypes.object.isRequired,
  optionPropName: PropTypes.string
}

export const AutocompleteText2 = ({ form, name, options, onChange, ...props }) => (
  <Controller
    name={name}
    control={form.control}
    render={({ field, fieldState, formState }) => (
      <Autocomplete
        // freeSolo
        disableClearable
        name={name}
        // inputRef={field.ref}
        value={field.value}
        onInputChange={(e, newValue) => {
          onChange ? onChange(e, newValue) : form.setValue(name, newValue, { shouldValidate: true })
        }}
        options={options} // [{ label: 'AAA', id: 1 }, { label: 'BBB', id: 2 }]
        sx={{ '& .MuiOutlinedInput-root': { paddingTop: '5px', paddingBottom: '5px' } }}
        renderInput={(params) => (
          <StyledTextField {...props} {...params} error={fieldState.invalid} helperText={fieldState.error?.message} />
        )}
      />
    )}
  />
)

AutocompleteText2.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func
}
