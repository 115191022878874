import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import PropTypes from 'prop-types'

export const LoadButton = ({ onClick, children, ...props }) => {
  const [loading, setLoading] = useState(false)

  /**
   * submitForm()で呼び出されるformik側のonSubmitは、
   * async関数にし、非同期呼び出しにawaitを使ってください。
   */
  const handleSubmit = async () => {
    setLoading(true)
    if (onClick) {
      await onClick()
    }
    // pageReflesh等の処理後にstate変更があると下記ワーニングが発生（React.18以降は発生しない）
    // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
    setLoading(false)
  }

  return (
    <LoadingButton {...props} onClick={handleSubmit} loading={loading}>
      {children}
    </LoadingButton>
  )
}

LoadButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any
}
