import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { memo, useEffect } from 'react'
import { TextField, Dialog, DialogText, MoreIconButton } from 'src/components'
// import { useStateMerge } from 'src/util/commons'
import { useApi, useStateManage } from 'src/util/'

const AUTH_GROUP = yup.object({
  auth_group_name: yup.string().required('権限グループ名は必須項目です。'),
  description: yup.string()
})

const AuthGroupMore = memo(({ init, selectedGroup }) => {
  console.log('AuthGroupMore')
  const request = useApi()
  const { state, setObject } = useStateManage({ 1: false, 2: false, 3: false })

  const handleDelete = (_selectedGroup) => {
    request.delete('/authGroup', { auth_group_id: _selectedGroup.auth_group_id }, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const itemList = [
    { itemName: '作成', call: () => setObject({ 1: true }) },
    { itemName: '編集', call: () => setObject({ 2: true }), disabled: () => selectedGroup.auth_group_id === '' },
    { itemName: '削除', call: () => handleDelete(selectedGroup), disabled: () => selectedGroup.auth_group_id === '' }
  ]

  const form = useForm({
    defaultValues: { auth_group_name: '', description: '' },
    resolver: yupResolver(AUTH_GROUP)
  })

  const onSubmitCreate = (values) => {
    request.post('/authGroup', values, () => {
      setObject({ 1: false })
      init({ createdFlg: true })
    })
  }

  const form2 = useForm({
    defaultValues: { auth_group_id: '', auth_group_name: '', description: '' },
    resolver: yupResolver(AUTH_GROUP)
  })

  const onSubmitEdit = (values) => {
    request.patch('/authGroup', values, () => {
      setObject({ 2: false })
      init()
    })
  }

  const initAuthGroupEdit = () => {
    form2.setValue('auth_group_id', selectedGroup.auth_group_id)
    form2.setValue('auth_group_name', selectedGroup.auth_group_name)
    form2.setValue('description', selectedGroup.description)
  }
  useEffect(initAuthGroupEdit, [selectedGroup.auth_group_id])

  return (
    <div>
      <MoreIconButton itemList={itemList} />

      <Dialog
        title="新規グループの作成"
        open={state[1]}
        close={{ dialog: () => setObject({ 1: false }), form: form.reset }}
        maxWidth="md"
      >
        <DialogContent>
          <DialogText text="作成するグループ情報を入力してください。" />
          <TextField
            form={form}
            sx={{ mt: '20px' }}
            name="auth_group_name"
            label="グループ名*"
            autoFocus
            inputProps={{ maxLength: 64 }}
            // margin="normal"
          />
          <TextField
            form={form}
            sx={{ mt: '20px' }}
            name="description"
            label="説明文"
            inputProps={{ maxLength: 64 }}
            // margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setObject({ 1: false })
              form.reset()
            }}
          >
            キャンセル
          </Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmitCreate)}>
            作成
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        title="グループの編集"
        open={state[2]}
        close={{ dialog: () => setObject({ 2: false }), form: form.reset }}
        maxWidth="md"
      >
        <DialogContent>
          <DialogText>{selectedGroup.auth_group_name}の編集内容を入力してください。</DialogText>
          <TextField
            form={form2}
            sx={{ mt: '20px' }}
            name="auth_group_name"
            label="グループ名*"
            inputProps={{ maxLength: 64 }}
          />
          <TextField
            form={form2}
            sx={{ mt: '20px' }}
            name="description"
            label="説明文"
            inputProps={{ maxLength: 64 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setObject({ 2: false })
              form2.reset()
            }}
          >
            キャンセル
          </Button>
          <Button type="submit" onClick={form2.handleSubmit(onSubmitEdit)}>
            変更
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

AuthGroupMore.propTypes = {
  init: PropTypes.func,
  selectedGroup: PropTypes.object
}

export default AuthGroupMore
