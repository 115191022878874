import { Card, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect } from 'react'
import { StyledCell, HeaderBox, FooterBox, Dialog, Pagination, SortCell } from 'src/components'
import { useApi, useStateManage, usePageControl } from 'src/util/'

const HistoryTable = () => {
  console.log('HistoryTable')
  const request = useApi()
  const pc = usePageControl()
  const { state, setObject } = useStateManage({ dataTotal: 0, pageTotal: 0, pageNo: 0, list: [], retryDialog: false })

  const search = (pageNo, values) => {
    values.pageNo = pageNo
    request.get('/rmt/loginHistory/page', values, ({ body }) => {
      setObject({
        dataTotal: body.page.dataTotal,
        pageTotal: body.page.pageTotal,
        pageNo,
        list: [...body.rows]
      })
    })
  }
  useEffect(() => pc.initSearch(search, () => setObject({ retryDialog: true })), [])

  const _handleSort = (_sort) => {
    if (state.dataTotal === 0) return
    pc.handleSort(_sort, () => setObject({ retryDialog: true }))
  }

  return (
    <Card>
      <HeaderBox title="リモートログイン履歴">{state.dataTotal !== 0 && '計' + state.dataTotal + '件'}</HeaderBox>
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <SortCell sx={{ minWidth: 140 }} name="remote_name" sort={pc.sort} onClick={_handleSort}>
                リモート名
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="host_name" sort={pc.sort} onClick={_handleSort}>
                ホスト名
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="user" sort={pc.sort} onClick={_handleSort}>
                ユーザ
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="login_time" sort={pc.sort} onClick={_handleSort}>
                ログイン時刻
              </SortCell>
              <SortCell sx={{ minWidth: 340 }} name="original_history" sort={pc.sort} onClick={_handleSort}>
                履歴（wtmp）
              </SortCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.dataTotal !== 0 &&
              state.list.map((row, index) => (
                <TableRow hover key={row.remote_id + '-' + row.no}>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.remote_name}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.host_name}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.user}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.login_time}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.original_history}</StyledCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FooterBox sx={{ justifyContent: 'center' }}>
        <Pagination
          state={state}
          setFormRequest={pc.setFormRequest}
          closeRetryDialog={() => setObject({ retryDialog: false })}
        />
      </FooterBox>
    </Card>
  )
}

export default HistoryTable
