import { Card, DialogContent, Table, TableHead, TableRow, TableBody, TableContainer } from '@mui/material'
import PropTypes from 'prop-types'
import { memo, useReducer, useEffect } from 'react'
import { Dialog, HeaderBox, RenderingSuppress, MoreIconButton, StyledCell } from 'src/components'
import { useApi } from 'src/util/'

const _set = (state, obj) => {
  return { ...state, ...obj }
}

/** 管理対象一覧 */
const DialogEjbcaTarget = ({ open, setDialog }) => {
  console.log('DialogEjbcaTarget')
  const request = useApi()
  const [state, setState] = useReducer(_set, { list: [] })

  const init = () => {
    if (!open) return
    request.get('/ejbcas/managedDb/list', null, ({ body }) => {
      setState({ list: [...body] })
    })
  }
  useEffect(init, [open])

  return (
    <Dialog title="EJBCA管理対象DB一覧" open={open} maxWidth="lg" close={() => setDialog({ open: false })}>
      <DialogContent sx={{ overflow: 'hidden', px: 2, pt: 0, pb: 2 }}>
        <HeaderBox>{state.list.length !== 0 && `計${state.list.length}件`}</HeaderBox>
        <Card>
          <TableContainer>
            <Table sx={{ borderCollapse: 'separate' }} size="small">
              <TableHead sx={{ wordBreak: 'keep-all' }}>
                <TableRow>
                  <StyledCell style={{ minWidth: 160, borderTop: 'none' }}>リモート名</StyledCell>
                  <StyledCell style={{ minWidth: 160, borderTop: 'none' }}>トンネル名</StyledCell>
                  <StyledCell style={{ minWidth: 120, borderTop: 'none' }}>アドレス</StyledCell>
                  <StyledCell style={{ minWidth: 120, borderTop: 'none' }}>ユーザ</StyledCell>
                  <StyledCell style={{ minWidth: 240, borderTop: 'none' }}>DB</StyledCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.list &&
                  state.list.map((row, index) => (
                    <TableRow hover key={row.ejbca_id}>
                      <StyledCell sx={{ wordBreak: 'break-all' }}>{row.remote_name}</StyledCell>
                      <StyledCell sx={{ wordBreak: 'break-all' }}>{row.remote_tunnel_name}</StyledCell>
                      <StyledCell sx={{ wordBreak: 'break-all' }}>
                        {row.host}:{row.port}
                      </StyledCell>
                      <StyledCell sx={{ wordBreak: 'break-all' }}>{row.user}</StyledCell>
                      <StyledCell sx={{ wordBreak: 'break-all' }}>{row.database}</StyledCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </DialogContent>
    </Dialog>
  )
}

DialogEjbcaTarget.propTypes = {
  open: PropTypes.bool,
  setDialog: PropTypes.func
}

const EjbcaSearchMore = memo(() => {
  console.log('EjbcaSearchMore')
  const [dialog, setDialog] = useReducer(_set, {
    1: { open: false }
  })

  const itemList = [{ itemName: '管理対象一覧', call: () => setDialog({ 1: { open: true } }) }]

  return (
    <>
      <MoreIconButton itemList={itemList} sx={{ my: '-8px' }} />
      <RenderingSuppress show={dialog[1].open}>
        <DialogEjbcaTarget open={dialog[1].open} setDialog={(obj) => setDialog({ 1: obj })} />
      </RenderingSuppress>
    </>
  )
})

export default EjbcaSearchMore
