import { Box, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

export const HeaderBox = ({ title, sx, children, ...props }) => {
  return (
    <Box
      py={1}
      px={2}
      sx={{ minHeight: '48px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...sx }}
      {...props}
    >
      {title && (
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  )
}

HeaderBox.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  sx: PropTypes.object,
  children: PropTypes.any
}
