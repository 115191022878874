import PropTypes from 'prop-types'
import SuccessOutlinedIcon from '@mui/material/internal/svg-icons/SuccessOutlined'
import ReportProblemOutlinedIcon from '@mui/material/internal/svg-icons/ReportProblemOutlined'
import ErrorOutlineIcon from '@mui/material/internal/svg-icons/ErrorOutline'

export const IconText = {
  Success: function Success({ text, mr = 1 }) {
    return (
      <>
        <SuccessOutlinedIcon sx={{ color: '#4caf50', verticalAlign: 'middle', ...(text && { marginRight: mr }) }} />
        {text}
      </>
    )
  },
  Error: function Error({ text, mr = 1 }) {
    return (
      <>
        <ErrorOutlineIcon sx={{ color: '#ef5350', verticalAlign: 'middle', ...(text && { marginRight: mr }) }} />
        {text}
      </>
    )
  },
  Warning: function Warning({ text, mr = 1 }) {
    return (
      <>
        <ReportProblemOutlinedIcon
          sx={{ color: '#ff9800', verticalAlign: 'middle', ...(text && { marginRight: mr }) }}
        />
        {text}
      </>
    )
  }
}

IconText.Success.propTypes = { text: PropTypes.any, mr: PropTypes.number }
IconText.Error.propTypes = { text: PropTypes.any, mr: PropTypes.number }
IconText.Warning.propTypes = { text: PropTypes.any, mr: PropTypes.number }
