import { useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import PropTypes from 'prop-types'
import { useApi } from 'src/util/'
import { downloadBlob } from 'src/util/commons'

export const DownloadButton = ({ fileName, label, reqParams, afterClick }) => {
  const [loading, setLoading] = useState(false)
  const request = useApi()

  const handleDownload = (e) => {
    setLoading(true)
    request[reqParams.method](
      reqParams.path,
      reqParams.sendData,
      ({ body }) => {
        setLoading(false)
        if (body.size) downloadBlob(fileName, body)
        if (afterClick) afterClick(e)
      },
      () => {
        setLoading(false)
      }
    )
  }

  return (
    <>
      <Button
        variant="outlined"
        sx={{ mr: 2 }}
        onClick={handleDownload}
        endIcon={loading ? <CircularProgress size={20} /> : <GetAppIcon color="action" />}
        disabled={loading}
      >
        {label}
      </Button>
    </>
  )
}

DownloadButton.propTypes = {
  fileName: PropTypes.string,
  label: PropTypes.string,
  reqParams: PropTypes.object,
  afterClick: PropTypes.func
}
