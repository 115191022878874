import { Helmet } from 'react-helmet'
import { Box, Container, Grid } from '@mui/material'
import { useState } from 'react'
import AuthGroup from 'src/contents/authManage/AuthGroup'
import AppMenuUsable from 'src/contents/authManage/AppMenuUsable'
import ApiRouteUsable from 'src/contents/authManage/ApiRouteUsable'
import { MainContent } from 'src/components'

const initSelectedGroup = {
  auth_group_id: '',
  auth_group_name: '',
  description: ''
}

const AuthManage = () => {
  // console.log('AuthManage')
  const [selectedGroup, setSelectedGroup] = useState(initSelectedGroup)

  return (
    <MainContent title="AuthManage | M System">
      <Container maxWidth="false">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AuthGroup selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} />
          </Grid>
          <Grid item xs={12}>
            <AppMenuUsable selectedGroup={selectedGroup} />
          </Grid>
          <Grid item xs={12}>
            <ApiRouteUsable selectedGroup={selectedGroup} />
          </Grid>
        </Grid>
      </Container>
    </MainContent>
  )
}

export default AuthManage
