import { Button, DialogActions, DialogContent, DialogContentText, Divider, Grid, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Dialog, DialogText, LoadButton, TextField, Loading, TextPassword } from 'src/components'
import { useApi, deleteNoValue, downloadBlob, useStateManage } from 'src/util/'
import dayjs from 'dayjs'

const REG_EDIT_FORM = yup.object({
  ca_name: yup.string().trim().required('CA名は必須項目です。'),
  subject_dn: yup.string().trim().required('DNは必須項目です。'),
  password: yup
    .string()
    .when('key_store_type', { is: 'P12', then: (s) => s.max(250).required('パスワードは必須項目です。') })
})
const CertIssue = ({ dialog, setDialog, searchVals }) => {
  console.log('CertIssue')
  const request = useApi()
  const { state, setObject } = useStateManage({ cas: [], ee_profiles: [], available_cert_profiles: [] })

  const form = useForm({
    defaultValues: {
      ejbca_id: '',
      ca_name: '',
      ee_username: '',
      ee_profile_name: '',
      cert_profile_name: '',
      issuer_dn: '',
      subject_dn: '',
      subject_alt_name: '',
      start_time: '',
      end_time: '',
      key_store_type: 'USERGENERATED',
      algorithm_strength: 'RSA,2048',
      password: ''
    },
    resolver: yupResolver(REG_EDIT_FORM)
  })

  const onSubmit = async (values) => {
    const [algorithm, strength] = values.algorithm_strength.split(',')
    values.algorithm = algorithm
    values.strength = strength
    delete values.algorithm_strength
    deleteNoValue(values)
    if (values.key_store_type === 'USERGENERATED') {
      await request.post('/ejbcas/certIssue', values, () => {
        document.getElementById('pageReflesh').click()
      })
    } else if (values.key_store_type === 'P12') {
      await request.post('/ejbcas/p12Issue', values, ({ body }) => {
        if (body.size) downloadBlob(`${dayjs().format('YYYYMMDD_HHmmss')}.p12`, body)
        document.getElementById('pageReflesh').click()
      })
    }
  }

  const handleEeProfChange = (e) => {
    const eeProfileName = e.target.value
    const eeProfile = state.ee_profiles.find((elem) => elem.ee_profile_name === eeProfileName)
    setObject({ available_cert_profiles: eeProfile.available_cert_profiles })
    form.setValue('ee_profile_name', eeProfileName)
    form.setValue('cert_profile_name', eeProfile.available_cert_profiles[0].name)
  }

  const close = {
    dialog: () => setDialog({ open: false }),
    form: form.reset
  }

  const [keyStoreType, setKeyStoreType] = useState('')
  const handleKeyStoreTypeChange = (value) => {
    form.setValue('key_store_type', value)
    form.setValue('password', '')
    setKeyStoreType(value)
  }

  useEffect(() => {
    if (!dialog.open) return
    form.setValue('key_store_type', 'USERGENERATED')
    handleKeyStoreTypeChange('USERGENERATED')
    form.setValue('algorithm_strength', 'RSA,2048')
    form.setValue('ejbca_id', searchVals.ejbca_id)
    request.setOption({ loading: 'load2' })
    request.get('/ejbcas/profileSelect', { ejbca_id: searchVals.ejbca_id }, ({ body }) => {
      const available_cert_profiles = body.ee_profiles[0].available_cert_profiles
      setObject({ cas: body.cas, ee_profiles: body.ee_profiles, available_cert_profiles })
      form.setValue('ca_name', body.cas[0].ca_name)
      form.setValue('ee_profile_name', body.ee_profiles[0].ee_profile_name)
      form.setValue('cert_profile_name', available_cert_profiles[0].name)
    })
  }, [dialog.open])

  return (
    <Dialog title="証明書の直接発行" open={dialog.open} close={close} maxWidth="lg">
      <Loading name="load2" sx={{ top: '-34px' }} initialDisplay />
      <DialogContent sx={{ p: 2 }}>
        <DialogText sx={{ mb: 2 }}>発行する証明書情報を入力してください。</DialogText>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField select form={form} name="ca_name" label="CA名*" autoFocus>
              {state.cas.map((item) => (
                <MenuItem key={item.ca_id} value={item.ca_name}>
                  {item.ca_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="ee_username" label="EEユーザ名" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField select form={form} name="ee_profile_name" label="EEプロファイル" onChange={handleEeProfChange}>
              {state.ee_profiles.map((item) => (
                <MenuItem key={item.ee_id} value={item.ee_profile_name}>
                  {item.ee_profile_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField select form={form} name="cert_profile_name" label="証明書プロファイル">
              {state.available_cert_profiles.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="subject_dn" label="DN*" inputProps={{ maxLength: 400 }} autoComplete="ON" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="subject_alt_name" label="SubjectAltName" inputProps={{ maxLength: 250 }} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <DialogText sx={{ mt: 2 }}>＊オプション項目 - 一部EJBCAプロファイルの設定が必要です。</DialogText>
          </Grid>
          <Grid item xs={6}>
            <TextField
              form={form}
              name="start_time"
              label="有効期限（開始）"
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              form={form}
              name="end_time"
              label="有効期限（終了）"
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              form={form}
              name="key_store_type"
              label="キーストアタイプ"
              onChange={(e) => handleKeyStoreTypeChange(e.target.value)}
            >
              <MenuItem value="USERGENERATED">証明書発行のみ</MenuItem>
              <MenuItem value="P12">PKCS12ダウンロード</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField select form={form} name="algorithm_strength" label="アルゴリズム-強度">
              <MenuItem value="RSA,2048">RSA-2048</MenuItem>
              <MenuItem value="RSA,4096">RSA-4096</MenuItem>
              <MenuItem value="RSA,8192">RSA-8192</MenuItem>
              <MenuItem value="ECDSA,P-256">ECDSA-P-256</MenuItem>
              <MenuItem value="ECDSA,P-384">ECDSA-P-384</MenuItem>
              <MenuItem value="ECDSA,P-521">ECDSA-P-521</MenuItem>
            </TextField>
          </Grid>
          {keyStoreType === 'P12' ? (
            <Grid item xs={12} sm={6}>
              <TextPassword form={form} name="password" label="パスワード*" />
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel"
          onClick={() => {
            close.dialog()
            close.form()
          }}
        >
          キャンセル
        </Button>
        <LoadButton type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </LoadButton>
      </DialogActions>
    </Dialog>
  )
}

CertIssue.propTypes = {
  dialog: PropTypes.object,
  setDialog: PropTypes.func,
  searchVals: PropTypes.object
}

export default CertIssue
