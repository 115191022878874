import { Box, Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { HeaderBox, TextField } from 'src/components'
import { usePageControl } from 'src/util/'
import EjbcaSearchMore from './EjbcaSearchMore'

export const EjbcaSearch = () => {
  console.log('EjbcaSearch')
  const pc = usePageControl()

  const form = useForm({
    defaultValues: { remote_name: '', host_name: '', ca_name: '' }
  })
  const onSubmit = (values) => {
    pc.setFormRequest(form, { btn: 'search', pageNo: 1, values })
  }

  useEffect(() => {
    form.handleSubmit(onSubmit)()
  }, [])

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <HeaderBox title="EJBCA 検索">
          <EjbcaSearchMore />
        </HeaderBox>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="remote_name" label="リモート名（前方一致）" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="host_name" label="ホスト名（前方一致）" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="ca_name" label="CA名（前方一致）" inputProps={{ maxLength: 64 }} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 1, px: 2 }}>
          <Button type="submit" color="primary" variant="contained">
            検索
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default EjbcaSearch
