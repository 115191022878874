import { Card, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { MoreIconButton, StyledCell, HeaderBox, FooterBox, Pagination, SortCell, Loading } from 'src/components'
import { useApi, useStateManage, usePageControl } from 'src/util/'
import UserTableMore from './UserTableMore'

const UserTable = ({ authGroupList }) => {
  console.log('UserTable')
  const request = useApi()
  const pc = usePageControl()
  const { state, setObject } = useStateManage({
    dataTotal: 0,
    pageTotal: 0,
    pageNo: 0,
    list: [],
    retryDialog: false,
    open: false,
    selectedUser: {}
  })

  const search = (pageNo, values) => {
    request.setOption({ loading: 'load1' })
    values.pageNo = pageNo
    request.get('/user/page', values, ({ body }) => {
      setObject({
        dataTotal: body.page.dataTotal,
        pageTotal: body.page.pageTotal,
        pageNo,
        list: [...body.rows]
      })
    })
  }

  useEffect(() => {
    pc.initSearch(search, () => setObject({ retryDialog: true }))
  }, [])

  const handleSort = (_sort) => {
    if (state.dataTotal === 0) return
    pc.handleSort(_sort, () => setObject({ retryDialog: true }))
  }

  const itemList2 = [
    {
      itemName: '編集',
      call: (callArg) => {
        setObject({ open: true, selectedUser: callArg })
      }
    },
    {
      itemName: '削除',
      call: (callArg) => {
        request.delete('/user', { user_id: callArg.user_id }, () => {
          document.getElementById('pageReflesh').click()
        })
      }
    }
  ]

  return (
    <Card>
      <Loading name="load1" sx={{ top: '10px' }} />
      <HeaderBox title="ユーザ検索結果">{state.dataTotal && '計' + state.dataTotal + '件'}</HeaderBox>
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <SortCell name="user_id" firstSortDescending sort={pc.sort} onClick={handleSort}>
                ID
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="user_name" sort={pc.sort} onClick={handleSort}>
                名前
              </SortCell>
              <SortCell sx={{ minWidth: 160 }} name="mail" sort={pc.sort} onClick={handleSort}>
                メール
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="auth_group_name" sort={pc.sort} onClick={handleSort}>
                権限グループ
              </SortCell>
              <SortCell sx={{ minWidth: 60 }} name="is_locked" sort={pc.sort} onClick={handleSort}>
                ロック
              </SortCell>
              <SortCell sx={{ minWidth: 110, width: 160 }} name="updated_at" sort={pc.sort} onClick={handleSort}>
                更新日
              </SortCell>
              <SortCell name="updater" sort={pc.sort} onClick={handleSort}>
                更新者
              </SortCell>
              <StyledCell sx={{ width: 24 }} />
              {/* <StyledCell>ID</StyledCell>
              <StyledCell sx={{ minWidth: 120 }}>名前</StyledCell>
              <StyledCell sx={{ minWidth: 160 }}>メール</StyledCell>
              <StyledCell sx={{ minWidth: 120 }}>権限グループ</StyledCell>
              <StyledCell sx={{ minWidth: 60 }}>ロック</StyledCell>
              <StyledCell sx={{ minWidth: 110, width: 160 }}>更新日</StyledCell>
              <StyledCell>更新者</StyledCell>
              <StyledCell sx={{ width: 24 }} /> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.list.map((row, index) => (
              <TableRow hover key={row.user_id}>
                <StyledCell>{row.user_id}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.user_name}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.mail}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.auth_group_name}</StyledCell>
                <StyledCell>{row.is_locked ? '有' : '無'}</StyledCell>
                <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.updated_at}</StyledCell>
                <StyledCell>{row.updater}</StyledCell>
                <StyledCell align="center" sx={{ p: '0', height: '42px' }}>
                  <MoreIconButton itemList={itemList2} callArg={row} icon="horiz" sx={{ m: '-8px' }} />
                </StyledCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FooterBox sx={{ justifyContent: 'center' }}>
        <Pagination
          state={state}
          setFormRequest={pc.setFormRequest}
          closeRetryDialog={() => setObject({ retryDialog: false })}
        />
      </FooterBox>
      <UserTableMore dialog={state} setDialog={setObject} authGroupList={authGroupList} />
    </Card>
  )
}

UserTable.propTypes = {
  authGroupList: PropTypes.array
}

export default UserTable
