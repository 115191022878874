import {
  Box,
  CssBaseline,
  Divider,
  Drawer as MuiDrawer,
  Hidden,
  Input,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  DonutLarge as ChartIcon,
  Person as UserIcon,
  Public as PublicIcon,
  Settings as SettingsIcon,
  ArrowDropDown,
  ArrowDropUp,
  Input as InputIcon
} from '@mui/icons-material'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { styled } from '@mui/material/styles'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import SidebarListItem from './SidebarListItem'

const drawerWidth = 256

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`
  // [theme.breakpoints.up('sm')]: {
  //   width: `calc(${theme.spacing(9)} + 1px)`,
  // },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const DashboardSidebar = ({ onMobileClose, openMobile, user, dispMenus }) => {
  console.log('DashboardSidebar')
  const [open, setOpen] = useState(true)
  const location = useLocation()

  useEffect(() => {
    if (openMobile) onMobileClose()
  }, [location.pathname])

  useEffect(() => {
    if (openMobile) setOpen(true)
  }, [openMobile])

  const handleShow = () => {
    if (openMobile) {
      setOpen(false)
      onMobileClose()
    } else {
      setOpen(!open)
    }
  }

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {user.loginStatus === 1 && (
        <>
          <Box sx={{ display: 'flex', py: 1, minHeight: '48px' }}>
            <Typography sx={{ m: 'auto' }} color="textPrimary" variant="h5">
              {open && user.name}
            </Typography>
            <Box sx={{ position: 'relative' }}>
              <IconButton
                id="sidebar-open-button"
                size="small"
                sx={{ position: 'absolute', left: `${open ? '-34px' : '-52px'}` }}
                onClick={handleShow}
              >
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </Box>
          </Box>
          <Divider variant="middle" />
        </>
      )}
      <Box sx={{ flexGrow: 1, p: 1 }}>
        <List>
          {dispMenus.map((item) => (
            <SidebarListItem
              key={item.app_menu_id + item.title}
              item={item}
              icon={item.icon}
              sidebarOpen={open}
              paddingLeft={2}
            />
          ))}
        </List>
      </Box>
      <Divider variant="middle" />
      <Hidden lgUp>
        <Box sx={{ p: 1 }}>
          <ListItemButton>
            <InputIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            <ListItemText
              primaryTypographyProps={{ pl: 1, fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.54)' }}
              onClick={() => document.getElementById('logoutRequest').click()}
            >
              ログアウト
            </ListItemText>
          </ListItemButton>
          {/* <IconButton color="inherit" size="large"><InputIcon />ログアウト</IconButton> */}
        </Box>
      </Hidden>
    </Box>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Hidden lgUp>
        <MuiDrawer variant="temporary" open={openMobile} onClose={onMobileClose} PaperProps={{ sx: { width: 256 } }}>
          {content}
        </MuiDrawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: { width: 256, top: 64, height: 'calc(100% - 64px)' }
          }}
        >
          <PerfectScrollbar>{content}</PerfectScrollbar>
        </Drawer>
      </Hidden>
    </Box>
  )
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  user: PropTypes.object,
  dispMenus: PropTypes.array
}

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
}

export default DashboardSidebar
