import { Box, Button, Collapse, DialogActions, DialogContent, Link, Divider } from '@mui/material'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Dialog, MoreIconButton, TextField } from 'src/components'
import { useApi, setFormInit, useStateManage, useMainRef } from 'src/util/'

const QR_CODE = yup.object({
  secret_key: yup.string().required(),
  authcode: yup.string().required().max(6)
})

const DialogTowAuthReg = ({ open, onClose }) => {
  console.log('DialogTowAuthReg')
  const request = useApi()
  const [expanded, setExpanded] = useState(false)

  const form = useForm({
    defaultValues: { qr_code: '', account_name: '', secret_key: '', authcode: '' },
    resolver: yupResolver(QR_CODE)
  })

  const close = {
    // ※ダイアログを閉じた際に、すべて初期化すること
    dialog: () => {
      onClose()
      form.reset()
      setExpanded(false)
    }
  }

  const onSubmit = (values) => {
    request.post('/twoFactor/register', { secret_key: values.secret_key, authcode: values.authcode }, () => {
      close.dialog()
      document.getElementById('pageReflesh').click()
    })
  }

  useEffect(() => {
    if (!open) return
    request.get('/twoFactor/qrCode', null, ({ body }) => {
      setFormInit(form, body)
      form.trigger('_render')
    })
  }, [open])

  return (
    <Dialog title="二要素認証の登録" open={open} close={close} maxWidth="md">
      <DialogContent sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', pb: 2 }}>
        <Box sx={{ pb: 2 }}>
          STEP1.スマートフォン等のモバイルデバイスに、二要素認証アプリをインストールしてください。
          <br />
          Google認証（推奨アプリ）：
          <Link
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            target="_blank"
          >
            Android版はこちら
          </Link>
          。
          <Link href="https://apps.apple.com/jp/app/google-authenticator/id388497605" target="_blank">
            iPhone版はこちら
          </Link>
          。
        </Box>

        <Divider />
        <Box sx={{ py: 2 }}>
          STEP2.アプリを起動し、下記QRコードをスキャンしてください。
          <br />
          <img src={form.getValues('qr_code')} alt="QRコード" />
          <br />
          <Link
            href="#"
            onClick={() => {
              setExpanded(!expanded)
            }}
          >
            QRコードを利用できない場合
          </Link>
          は、
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box sx={{ mb: 1 }}>下記のセットアップキーを入力してください。</Box>
            アカウント名：{form.getValues('account_name')}（任意）
            <br />
            キー：{form.getValues('secret_key')}
          </Collapse>
        </Box>

        <Divider />
        <Box sx={{ pt: 2 }}>
          STEP3.アプリに表示された、6桁の確認コードを入力してください。
          <TextField form={form} name="authcode" label="確認コード*" sx={{ mt: 2 }} inputProps={{ maxLength: 6 }} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid #e0e0e0' }}>
        <Button onClick={close.dialog}>キャンセル</Button>
        <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogTowAuthReg.propTypes = { open: PropTypes.bool, onClose: PropTypes.func }

const DialogTowAuthDel = ({ open, onClose }) => {
  console.log('DialogTowAuthDel')
  const request = useApi()

  const handleDelete = () => {
    request.delete('/twoFactor/register', null, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  return (
    <Dialog title="二要素認証の削除" open={open} close={onClose} maxWidth="md">
      <DialogContent>
        ログイン時の二要素認証コードを入力する必要がなくなります。
        <br />
        二要素認証アプリのアカウント登録情報については、アプリから手動で削除する必要があります。
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid #e0e0e0' }}>
        <Button onClick={onClose}>キャンセル</Button>
        <Button type="submit" onClick={handleDelete}>
          削除
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogTowAuthDel.propTypes = { open: PropTypes.bool, onClose: PropTypes.func }

const SettingsPasswordMore = () => {
  console.log('SettingsPasswordMore')
  const ref = useMainRef()
  const { state, setValue } = useStateManage({ 1: false, 2: false })

  const itemList = [
    { itemName: '二要素認証の登録', call: () => setValue(1, true) },
    {
      itemName: '二要素認証の削除',
      call: () => setValue(2, true),
      disabled: () => ref.get('is_tow_auth') === 0
    }
  ]

  return (
    <>
      <MoreIconButton itemList={itemList} sx={{ my: '-8px' }} />
      <DialogTowAuthReg open={state[1]} onClose={() => setValue(1, false)} />
      <DialogTowAuthDel open={state[2]} onClose={() => setValue(2, false)} />
    </>
  )
}

export default SettingsPasswordMore
