/* eslint-disable */
import { useState, useRef, useCallback, useEffect } from 'react'

/**
 * ステートオブジェクトを管理するメソッドを提供する。
 * @template T
 * @param {T} initObj
 */
export const useStateManage = (initObj) => {
  const [_state, _setState] = useState(initObj)

  /** @type {T} */
  const state = _state
  /** @type {T} */
  const _default = JSON.parse(JSON.stringify(initObj))
  const ref = useRef(initObj)

  /**
   * オブジェクトの差分でステートを更新する。
   * プロパティは、浅いコピーのマージで更新される。
   * @param {T} obj
   */
  const setObject = (obj) => {
    const newState = { ...ref.current, ...obj }
    _setState({ ...newState, ...obj })
    ref.current = newState
  }

  /**
   * オブジェクトのプロパティ階層を指定して、値を更新する。
   * @param {Array | number | keyof T} propKeys
   * @param {*} value
   * @example
   * const sm = useStateManage({ 1: { open: false }, 2: { open: false } })
   * sm.setValue([2, 'open'], true)
   */
  const setValue = (propKeys, value) => {
    const copyObj = { ...ref.current }
    if (typeof propKeys === 'string' || propKeys instanceof String || Number.isFinite(propKeys)) {
      copyObj[propKeys] = value
      setObject(copyObj)
      return
    }

    let prop = copyObj
    for (let i = 0; i < propKeys.length; i++) {
      const nest = prop[propKeys[i]]
      if (nest === undefined) {
        prop[propKeys[i]] = {}
      }
      if (i === propKeys.length - 1) {
        prop[propKeys[i]] = value
      } else {
        prop = prop[propKeys[i]]
      }
    }
    setObject(copyObj)
  }

  /**
   * ステートを初期オブジェクトにリセットする。
   */
  const reset = () => {
    _setState(initObj)
    ref.current = initObj
  }

  return { state, setValue, setObject, reset, _default }
}
