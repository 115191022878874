import { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Alert } from 'src/components'
import { MENU_ROUTE_PUBLIC, useMenuRout } from 'src/Rout'
import { useApi } from 'src/util/'
import DashboardSidebar from './DashboardSidebar'
import DashboardNavbar from './DashboardNavbar'

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  width: '100%',
  overflow: 'hidden'
}))

const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: { pl: 0 } // ウィンドウサイズ変更用
}))

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflowY: 'scroll'
})

const DashboardLayout = ({ global, render }) => {
  console.log('DashboardLayout: ')

  const request = useApi({ global, render })
  const initializeMenu = useMenuRout(global, render)

  const activeDialog = document.getElementById('activeDialog')
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)

  const handleBeforeUnloadEvent = (e) => {
    if (global.user.token) sessionStorage.setItem('token', global.user.token)
    // e.returnValue = ''
  }

  if (!global.user.token) {
    global.user.token = sessionStorage.getItem('token')
    sessionStorage.removeItem('token')
  }

  useEffect(() => {
    console.log('DashboardLayout useEffect')
    if (global.user.loginStatus !== 1) {
      // F5押下時
      if (global.user.token) {
        request.get('/myInfo', null, ({ body }) => {
          initializeMenu(body.menu, body.allowedMenuPaths, window.location.pathname)
        })
      } else if (window.location.pathname === '/app/publicInfo') {
        global.dispMenus = MENU_ROUTE_PUBLIC
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnloadEvent)
    return () => window.removeEventListener('beforeunload', handleBeforeUnloadEvent)
  }, [])

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} global={global} render={render} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        user={global.user}
        dispMenus={global.dispMenus}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContent id="contents">
          {activeDialog &&
            activeDialog.value === '' &&
            global.messages.map((message, i = 0) => (
              <Alert
                key={i}
                open={message.open}
                onClose={() => render('messages', i, 'open', false)}
                severity={message.severity}
              >
                {message.body}
              </Alert>
            ))}
          <Outlet context={[global, render]} />
        </DashboardLayoutContent>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  )
}

DashboardLayout.propTypes = {
  global: PropTypes.object,
  render: PropTypes.func
}

export default DashboardLayout
