import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Box,
  Card,
  Divider,
  Grid,
  Hidden,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { HeaderBox, StyledCell, FooterBox } from 'src/components'
import UrlPagination from './UrlPagination'

const CheckedDataList = ({ response = {}, onClickPage, pageNo }) => {
  // console.log('CheckedDataList')
  const pageCount = response.pageCount
  // const [top, isTop] = useState(false)
  // const handleToggle = () => { top ? isTop(false) : isTop(true) }

  return (
    <Card>
      {/* <CardHeader disableTypography="true" title="集計詳細" /> */}
      <HeaderBox title="集計詳細" />
      {/* <Grid container p={2} alignItems="center">
        <Grid item xs={12} sm={2}><Typography variant="h5">集計詳細</Typography></Grid>
        {top && (
          <Grid item xs={12} sm={8}>
            <Hidden implementation="css" smUp>
              <Divider sx={{ margin: '10px' }} />
            </Hidden>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <UrlPagination pageCount={pageCount} pageNo={pageNo} onChange={onClickPage} onToggle={handleToggle} />
            </Box>
          </Grid>
        )}
        <Grid item sm={2} />
      </Grid> */}

      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <StyledCell>バッチ履歴No</StyledCell>
              <StyledCell sx={{ minWidth: 210 }}>URL</StyledCell>
              <StyledCell>ステータス</StyledCell>
              <StyledCell sx={{ minWidth: 210 }}>メッセージ</StyledCell>
              <StyledCell sx={{ minWidth: 120, width: 120 }}>チェック時間</StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {response.rows2 &&
              response.rows2.map((row) => (
                <TableRow hover key={row.history_no + '_' + row.url_id}>
                  <StyledCell>{row.history_no}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.url}</StyledCell>
                  <StyledCell>{row.status_kbn + ': ' + row.status_cd}</StyledCell>
                  <StyledCell>{row.message}</StyledCell>
                  <StyledCell>{row.checked_at}</StyledCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <FooterBox sx={{ borderTop: '1px solid #e0e0e0' }}> */}
      <FooterBox sx={{ justifyContent: 'center' }}>
        <UrlPagination pageCount={pageCount} pageNo={pageNo} onChange={onClickPage} />
      </FooterBox>
      {/* {!top && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 2, borderTop: '1px solid #e0e0e0' }}>
          <UrlPagination pageCount={pageCount} pageNo={pageNo} onChange={onClickPage} onToggle={handleToggle} />
        </Box>
      )} */}
    </Card>
  )
}

CheckedDataList.propTypes = {
  response: PropTypes.object,
  onClickPage: PropTypes.func,
  pageNo: PropTypes.number
}

export default CheckedDataList
