import { useState, memo } from 'react'
import { Box, IconButton, Menu, MenuItem, Divider } from '@mui/material'
import {
  MoreHoriz as MoreHorizIcon,
  MoreVert as MoreVertIcon,
  AddCircleOutline as AddCircleOutlineIcon
} from '@mui/icons-material'
import PropTypes from 'prop-types'

export const MoreIconButton = memo(({ itemList, callArg, icon, sx, children }) => {
  console.log('MoreIconButton')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <IconButton
        id="long-button"
        sx={sx}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
      >
        {!children ? icon === 'horiz' ? <MoreHorizIcon /> : <MoreVertIcon /> : children}
      </IconButton>
      {itemList && (
        <Menu
          id="long-menu"
          MenuListProps={{ 'aria-labelledby': 'long-button' }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          PaperProps={{ style: { maxHeight: 150, minWidth: '20ch' } }}
        >
          {itemList.map((item) =>
            item.divider ? (
              <Divider key={item.itemName} />
            ) : (
              <MenuItem
                key={item.itemName}
                onClick={() => {
                  setAnchorEl(null)
                  item.call(callArg)
                }}
                disabled={item.disabled && item.disabled(callArg)}
              >
                {item.itemName}
                {item.body && item.body(callArg)}
              </MenuItem>
            )
          )}
        </Menu>
      )}
    </>
  )
})

MoreIconButton.propTypes = {
  itemList: PropTypes.array,
  callArg: PropTypes.any,
  icon: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.any
}

export const MoreIconAddButton = ({ itemList, callArg }) => {
  return (
    <Box component="span" sx={{ position: 'relative' }}>
      <MoreIconButton itemList={itemList} callArg={callArg} sx={{ position: 'absolute', top: '2px', left: '4px' }}>
        <AddCircleOutlineIcon />
      </MoreIconButton>
    </Box>
  )
}

MoreIconAddButton.propTypes = {
  itemList: PropTypes.array,
  callArg: PropTypes.any
}
