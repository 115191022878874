import { Button, DialogActions, DialogContent, Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { memo } from 'react'
import * as yup from 'yup'
import { Dialog, DialogText, TextField, MoreIconButton, RenderingSuppress } from 'src/components'
import { useApi, useStateManage } from 'src/util/'

const CONTRACT_ADD = yup.object({
  contract_name: yup.string().required('契約名は必須項目です。'),
  contract_user_prefix: yup.string().required('プレフィックスは必須項目です。'),
  address: yup.string(),
  tel_no: yup.string(),
  description: yup.string()
})

const ContractAdd = ({ state, setState }) => {
  console.log('ContractAdd')
  const request = useApi({ cleareMessage: true })

  const form = useForm({
    defaultValues: { contract_name: '', contract_user_prefix: '', address: '', tel_no: '', description: '' },
    resolver: yupResolver(CONTRACT_ADD)
  })

  const onSubmit = (values) => {
    request.post('/contract', values, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const handleReset = () => {
    setState({ open: false })
    form.reset()
  }

  return (
    <Dialog
      title="契約作成"
      open={state.open}
      close={{ dialog: () => setState({ open: false }), form: form.reset }}
      maxWidth="lg"
    >
      <DialogContent sx={{ overflow: 'hidden', p: 2 }}>
        <DialogText sx={{ mb: 2 }}>作成する契約情報を入力してください。</DialogText>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="contract_name" label="契約名*" inputProps={{ maxLength: 64 }} autoFocus />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="contract_user_prefix" label="プレフィックス*" inputProps={{ maxLength: 8 }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="address" label="住所" inputProps={{ maxLength: 64 }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="tel_no" label="電話番号" inputProps={{ maxLength: 32 }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="description" label="説明" inputProps={{ maxLength: 64 }} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset}>キャンセル</Button>
        <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ContractAdd.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func
}

const ContractSearchMore = memo(() => {
  console.log('ContractSearchMore')
  const { state, setValue } = useStateManage({
    1: { open: false }
  })

  const itemList = [{ itemName: '契約作成', call: () => setValue(1, { open: true }) }]

  return (
    <>
      <MoreIconButton itemList={itemList} sx={{ my: '-8px' }} />
      <RenderingSuppress show={state[1].open}>
        <ContractAdd state={state[1]} setState={(obj) => setValue(1, obj)} />
      </RenderingSuppress>
    </>
  )
})

export default ContractSearchMore
