import React from 'react'
import { Container, Grid } from '@mui/material'
import HistorySearch from 'src/contents/remotoLoginHistory/HistorySearch'
import HistoryTable from 'src/contents/remotoLoginHistory/HistoryTable'
import { MainProvider } from 'src/util/'
import { MainContent } from 'src/components'

const RemotoLoginHistory = () => {
  console.log('RemotoLoginHistory')

  return (
    <MainContent title="RemotoLoginHistory | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <HistorySearch />
            </Grid>
            <Grid item xs={12}>
              <HistoryTable />
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default RemotoLoginHistory
