import { Box } from '@mui/material'
import PropTypes from 'prop-types'

// export const FileDragArea = ({ fileId, children, callback, ...props }) => {
//   return (
//     <Box {...props}>
//       <div id="FileDragArea" style={{ position: 'relative' }} value="0"
//         onDragOver={(e) => {
//           e.preventDefault(); e.stopPropagation()
//         }}
//         onDragEnter={(e) => {
//           e.preventDefault(); e.stopPropagation()
//           const elem = document.getElementById('FileDragArea')
//           if (elem.value === undefined) elem.value = 0
//           elem.value += 1
//           elem.style.backgroundColor = '#e8f0fe'
//         }}
//         onDragLeave={(e) => {
//           e.preventDefault(); e.stopPropagation()
//           const elem = document.getElementById('FileDragArea')
//           elem.value -= 1
//           // 要素内でLeaveする対策（テキストエリアで発生）
//           if (elem.value === 0) {
//             elem.style.backgroundColor = '#fff'
//           }
//         }}
//         onDrop={(e) => {
//           e.preventDefault(); e.stopPropagation()
//           const elem = document.getElementById('FileDragArea')
//           elem.value = 0
//           elem.style.backgroundColor = '#fff'
//           const elm = document.getElementById(fileId)
//           elm.files = e.dataTransfer.files
//           callback(e)
//         }}
//       >
//         {children}
//       </div>
//     </Box>
//   )
// }
const DEFAULT_AREA = 'file-drag-area-default'
export const FileDragArea = ({ fileId, areaId, children, callback, ...props }) => {
  return (
    <Box
      {...props}
      id={areaId ? undefined : DEFAULT_AREA}
      onDragOver={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onDragEnter={(e) => {
        e.preventDefault()
        e.stopPropagation()
        const elem = document.getElementById(areaId || DEFAULT_AREA)
        if (elem.value === undefined) elem.value = 0
        elem.value += 1
        elem.style.backgroundColor = '#e8f0fe'
      }}
      onDragLeave={(e) => {
        e.preventDefault()
        e.stopPropagation()
        const elem = document.getElementById(areaId || DEFAULT_AREA)
        elem.value -= 1
        // 要素内でLeaveする対策（テキストエリアで発生）
        if (elem.value === 0) {
          elem.style.backgroundColor = '#fff'
        }
      }}
      onDrop={(e) => {
        e.preventDefault()
        e.stopPropagation()
        const elem = document.getElementById(areaId || DEFAULT_AREA)
        elem.value = 0
        elem.style.backgroundColor = '#fff'
        const elm = document.getElementById(fileId)
        elm.files = e.dataTransfer.files
        callback(e)
      }}
    >
      {children}
    </Box>
  )
}

FileDragArea.propTypes = {
  fileId: PropTypes.string.isRequired,
  areaId: PropTypes.string,
  children: PropTypes.any,
  callback: PropTypes.func.isRequired
}
