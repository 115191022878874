import { Collapse, Container, Grid } from '@mui/material'
import { useState, useEffect } from 'react'
import RemoteSelect from 'src/contents/remotoConect/RemoteSelect'
import RemoteDisplay from 'src/contents/remotoConect/RemoteDisplay'
import { useApi, MainProvider } from 'src/util/'
import { MainContent } from 'src/components'

function SelectList(keyName) {
  this.keyName = keyName
  this.hide = false
  this.selectedKey = ''
  this.list = []
  this.listKeyMap = {}

  // thisの代わりに引数でインスタンス指定（クローンオブジェクトで呼び出すため）
  this.getRecord = (_this) => {
    const result = _this.list.find((item) => item[_this.keyName] === _this.selectedKey)
    return result
  }
  this.init = null // 初期化関数
  this.drow = null // useState()のset関数
}

const ServerConect = () => {
  console.log('ServerConect')
  const request = useApi()
  const [conn, setConnection] = useState(new SelectList('remote_id'))

  conn.drow = (obj) => setConnection({ ...conn, ...obj })
  conn.init = (remote_id) => {
    request.get('/rmt/sshConnection/list', null, ({ body }) => {
      conn.list = body
      conn.selectedKey = remote_id || ''
      conn.drow()
    })
  }
  useEffect(conn.init, [])

  return (
    <MainContent title="RemotoConect | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container>
            <Grid item xs={12}>
              <Collapse in={!conn.hide}>
                <RemoteSelect sx={{ mb: 3 }} conn={conn} />
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              {conn.selectedKey && <RemoteDisplay conn={conn} />}
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default ServerConect
