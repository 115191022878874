import { Button, DialogActions, DialogContent, FormControl, MenuItem } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { TextField, Dialog, DialogText, MoreIconButton } from 'src/components'
import { useApi, useStateManage } from 'src/util/'

const URL_ADD = yup.object({
  url: yup
    .string()
    .required('URLは必須項目です。')
    .matches(/^https?:\/\/./i, 'URLはhttp://またはhttps://を入力してください。')
})

const URL_DEL = yup.object({
  url_id: yup.number().required('削除対象を選択してください。')
})

const UrlMoniSettingsMore = ({ init, form }) => {
  console.log('UrlMoniSettingsMore')
  const request = useApi()
  const { state, setValue } = useStateManage({ 1: false, 2: false })

  const itemList = [
    { itemName: '追加', call: () => setValue(1, true) },
    { itemName: '削除', call: () => setValue(2, true) }
  ]

  const formAdd = useForm({
    defaultValues: { url: 'https://' },
    resolver: yupResolver(URL_ADD)
  })

  const onSubmitAdd = (values) => {
    setValue(1, false)
    request.post('/moni/mstUrl', values, () => {
      init()
    })
  }

  const formDel = useForm({
    defaultValues: { url_id: '' },
    resolver: yupResolver(URL_DEL)
  })

  const onSubmitDel = (values) => {
    setValue(2, false)
    formDel.reset()
    request.delete('/moni/mstUrl', values, () => {
      init()
    })
  }

  return (
    <div>
      <MoreIconButton itemList={itemList} sx={{ my: '-8px' }} />
      <Dialog
        title="URL監視対象の追加"
        open={state[1]}
        close={{ dialog: () => setValue(1, false), form: formAdd.reset }}
        maxWidth="md"
      >
        <DialogContent>
          <DialogText text="監視対象に追加するURLを入力してください。" />
          <TextField
            form={formAdd}
            name="url"
            sx={{ marginTop: '20px' }}
            autoFocus
            label="URL"
            inputProps={{ maxLength: 255 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValue(1, false)
              formAdd.reset()
            }}
          >
            キャンセル
          </Button>
          <Button type="submit" onClick={formAdd.handleSubmit(onSubmitAdd)}>
            送信
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        title="URL監視対象の削除"
        open={state[2]}
        close={{ dialog: () => setValue(2, false), form: formDel.reset }}
        maxWidth="md"
      >
        <DialogContent>
          <DialogText text="削除する監視対象を選択してください。" />
          <FormControl fullWidth sx={{ marginTop: '20px' }}>
            <TextField
              form={formDel}
              name="url_id"
              select
              fullWidth
              label="No : URL"
              InputLabelProps={{ shrink: true }}
            >
              {form &&
                form.map((row, index) => (
                  <MenuItem key={row.url_id} value={row.url_id}>
                    {row.no} : {row.url}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValue(2, false)
              formDel.reset()
            }}
          >
            キャンセル
          </Button>
          <Button type="submit" onClick={formDel.handleSubmit(onSubmitDel)}>
            送信
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

UrlMoniSettingsMore.propTypes = {
  init: PropTypes.func,
  form: PropTypes.array
}

export default UrlMoniSettingsMore
