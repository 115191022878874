import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import { Box, Card, Divider, Typography, colors } from '@mui/material'
import { HeaderBox } from 'src/components'

ChartJS.register(ArcElement, Tooltip, Legend)

let rateData = [
  {
    status_kbn: '成功',
    count: 0,
    rate: 0,
    color: colors.green[500]
  },
  {
    status_kbn: '失敗',
    count: 0,
    rate: 0,
    color: colors.red[600]
  },
  {
    status_kbn: '警告',
    count: 0,
    rate: 0,
    color: colors.orange[600]
  }
]

const options = {
  responsive: true,
  cutout: 80,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      // position: 'top',
      display: false
    }
    // tooltip: {}
  }
}

const StatusRate = React.memo(({ response = {} }) => {
  const records = response.rows

  // rateDataをレコード内容で更新する
  if (records) {
    const rateDataObj = {}
    records.forEach((record) => {
      rateDataObj[record.status_kbn] = record
    })

    rateData = rateData.map((elm) => {
      let data = rateDataObj[elm.status_kbn]
      if (!data) {
        data = { count: 0, rate: 0 }
      }
      return { ...elm, ...data }
    })
  }

  const doughnutData = {
    labels: [' 成功', ' 失敗', ' 警告'],
    datasets: [
      {
        data: [rateData[0].count, rateData[1].count, rateData[2].count],
        backgroundColor: [rateData[0].color, rateData[1].color, rateData[2].color],
        borderWidth: 2,
        borderColor: colors.common.white
        // hoverBorderColor: colors.common.white
      }
    ]
  }

  const total = doughnutData.datasets[0].data.reduce((sum, elem) => {
    return sum + elem
  })

  return (
    <Card sx={{ height: '100%' }}>
      <HeaderBox title="集計ステータス割合" sx={{ minHeight: '54px' }}>
        <Box sx={{ fontSize: '0.875rem' }}>{total}件中</Box>
      </HeaderBox>
      <Divider />
      <Box sx={{ mt: 2, height: 200, position: 'relative' }}>
        <Doughnut data={doughnutData} options={options} />
      </Box>
      <Box sx={{ py: 1, display: 'flex', justifyContent: 'center' }}>
        {rateData.map(({ status_kbn, rate, color }) => (
          <Box key={status_kbn} sx={{ px: 1, textAlign: 'center' }}>
            <Typography color="textPrimary" variant="body1">
              {status_kbn}
            </Typography>
            <Typography style={{ color }} variant="h3">
              {rate}%
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  )
})

StatusRate.propTypes = {
  response: PropTypes.object
}

export default StatusRate
