import { Box, Grid, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useState, useReducer } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import BatchTable from 'src/contents/batchManage/BatchTable'
import BatHistorySearch from 'src/contents/batchManage/batchHistory/BatHistorySearch'
import BatHistoryTable from 'src/contents/batchManage/batchHistory/BatHistoryTable'
import { MainProvider } from 'src/util/'
import { MainContent } from 'src/components'

const BatchLogTab = () => {
  console.log('BatchLogTab')

  return (
    // <Box sx={{ backgroundColor: 'background.default', py: 3 }}>
    // <Container maxWidth="false" sx={{ p: 0 }}>
    <MainProvider>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BatHistorySearch />
        </Grid>
        <Grid item xs={12}>
          <BatHistoryTable />
        </Grid>
      </Grid>
    </MainProvider>
    // </Container>
    // </Box>
  )
}

const LinkTab = ({ value, ...props }) => {
  return <Tab component={RouterLink} to={value} value={value} {...props} />
}
LinkTab.propTypes = {
  value: PropTypes.string
}

const BatchManage = () => {
  console.log('BatchManage')
  const [tabValue, setTabValue] = useState(window.location.pathname)
  const handleChange = (e, newValue) => setTabValue(newValue)

  return (
    <MainContent title="BatchManage | M System" sx={{ my: 0 }}>
      <TabContext id="TabContext" value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3 }}>
          <TabList onChange={handleChange} aria-label="aria1">
            <LinkTab label="バッチ管理一覧" value="/app/batchManage" />
            <LinkTab label="バッチ履歴" value="/app/batchManage/history" />
          </TabList>
        </Box>
        <TabPanel value="/app/batchManage">
          <BatchTable />
        </TabPanel>
        <TabPanel value="/app/batchManage/history">
          <BatchLogTab />
        </TabPanel>
      </TabContext>
    </MainContent>
  )
}

export default BatchManage
