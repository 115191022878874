import { Card, Box, Button, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect, useState, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Dialog, FooterBox, HeaderBox, Pagination, SortCell, StyledCell } from 'src/components'

import { useApi, useStateManage, usePageControl } from 'src/util/'

const BatHistoryTable = () => {
  console.log('BatHistoryTable')
  const request = useApi()
  const pc = usePageControl()
  const { state, setObject } = useStateManage({
    dataTotal: 0,
    pageTotal: 0,
    pageNo: 0,
    list: [],
    retryDialog: false,
    dialog2: false,
    row: {}
  })

  const search = (pageNo, values) => {
    values.pageNo = pageNo
    request.get('/bat/runHistory/page', values, ({ body }) => {
      setObject({
        dataTotal: body.page.dataTotal,
        pageTotal: body.page.pageTotal,
        pageNo,
        list: [...body.rows]
      })
    })
  }
  useEffect(() => pc.initSearch(search, () => setObject({ retryDialog: true })), [])

  const handleSort = (_sort) => {
    if (state.dataTotal === 0) return
    pc.handleSort(_sort, () => setObject({ retryDialog: true }))
  }

  const handleLog = (row) => {
    request.get('/bat/runHistoryLog', { history_no: row.history_no }, ({ body }) => {
      setObject({
        dialog2: true,
        row: { batch_file_name: row.batch_file_name, history_no: body.history_no, log: body.log }
      })
    })
  }

  return (
    <Card>
      <HeaderBox title="バッチ実行履歴">{state.dataTotal !== 0 && '計' + state.dataTotal + '件'}</HeaderBox>
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <SortCell sx={{ minWidth: 80 }} name="history_no" sort={pc.sort} onClick={handleSort}>
                No
              </SortCell>
              <SortCell sx={{ minWidth: 180 }} name="batch_file_name" sort={pc.sort} onClick={handleSort}>
                バッチ名
              </SortCell>
              <SortCell sx={{ minWidth: 180 }} name="batch_args" sort={pc.sort} onClick={handleSort}>
                引数
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="started_at" sort={pc.sort} onClick={handleSort}>
                開始時刻
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="ended_at" sort={pc.sort} onClick={handleSort}>
                終了時刻
              </SortCell>
              <SortCell sx={{ minWidth: 90 }} name="exit_cd" sort={pc.sort} onClick={handleSort}>
                終了コード
              </SortCell>
              <SortCell sx={{ minWidth: 240 }} name="result" sort={pc.sort} onClick={handleSort}>
                終了結果
              </SortCell>
              <StyledCell sx={{ minWidth: 80 }} name="log">
                ログ
              </StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.dataTotal !== 0 &&
              state.list.map((row, index) => (
                <TableRow hover key={row.history_no}>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.history_no}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.batch_file_name}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.batch_args}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.started_at}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.ended_at}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.exit_cd}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.result}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>
                    <Button fullWidth sx={{ my: '-6px', ml: '-8px' }} onClick={() => handleLog(row)}>
                      詳細…
                    </Button>
                  </StyledCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FooterBox sx={{ justifyContent: 'center' }}>
        <Pagination
          state={state}
          setFormRequest={pc.setFormRequest}
          closeRetryDialog={() => setObject({ retryDialog: false })}
        />
        <Dialog
          title={`${state.row?.batch_file_name} : No.${state.row?.history_no}`}
          maxWidth="xl"
          open={state.dialog2}
          close={() => setObject({ dialog2: false })}
        >
          <Box sx={{ fontSize: '13px', color: '#24292f', maxHeight: '720px', p: '8px', overflow: 'scroll' }}>
            <pre>{state.row?.log}</pre>
          </Box>
          {/* <TextField variant="standard" multiline fullWidth maxRows={30}
            value={tableState.log}
          /> */}
        </Dialog>
      </FooterBox>
    </Card>
  )
}

export default BatHistoryTable
