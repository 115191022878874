import { Container, Grid } from '@mui/material'
import { useState, useEffect } from 'react'
import UserTable from 'src/contents/userManage/UserTable'
import UserSearch from 'src/contents/userManage/UserSearch'
import { useApi, MainProvider } from 'src/util/'
import { MainContent } from 'src/components'

const UserManage = () => {
  console.log('UserManage')
  const request = useApi()
  const [authGroupList, setAuthGroupList] = useState()

  useEffect(() => {
    request.get('/authGroup/list', null, ({ body }) => {
      setAuthGroupList(body)
    })
  }, [])

  return (
    <MainContent title="UserManage | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserSearch authGroupList={authGroupList} />
            </Grid>
            <Grid item xs={12}>
              <UserTable authGroupList={authGroupList} />
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default UserManage
