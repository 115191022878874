import PropTypes from 'prop-types'
import SortCell from './SortCell'

const searchParentNode = (tagName, e) => {
  let parent = e.parentNode
  for (let i = 0; i < 9; i++) {
    if (parent.tagName === tagName) return parent
    parent = parent.parentNode
  }
  throw new Error(`[${tagName}]のParentNodeが見つかりません。`)
}

const SortCellLocal = ({ setSort, children, ...props }) => {
  const handlClick = (sort, e) => {
    const tr = searchParentNode('TR', e.currentTarget)
    const columnNo = tr.children[sort.name].cellIndex

    const table = searchParentNode('TABLE', tr)
    if (table.rows.length === 1) return

    let numericalComparison = true
    const sortArray = []
    for (let i = 1; i < table.rows.length; i++) {
      const column = {}
      column.row = table.rows[i]
      column.value = table.rows[i].cells[columnNo].textContent
      sortArray.push(column)
      if (column.value === '') column.value = '-1'
      else if (Number.isNaN(Number(column.value))) numericalComparison = false
    }

    if (numericalComparison) {
      if (sort.desc) {
        sortArray.sort((a, b) => b.value - a.value)
      } else {
        sortArray.sort((a, b) => a.value - b.value)
      }
    } else {
      if (sort.desc) {
        sortArray.sort((a, b) => {
          return a.value > b.value ? -1 : a.value < b.value ? 1 : 0
        })
      } else {
        sortArray.sort((a, b) => {
          return a.value < b.value ? -1 : a.value > b.value ? 1 : 0
        })
      }
    }

    const tbody = table.getElementsByTagName('TBODY')[0]
    sortArray.forEach((item) => tbody.appendChild(item.row))
    setSort(sort)
  }

  return (
    <SortCell {...props} onClick={handlClick}>
      {children}
    </SortCell>
  )
}

SortCellLocal.propTypes = {
  setSort: PropTypes.func,
  children: PropTypes.any
}

export default SortCellLocal
