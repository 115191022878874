import { useState, useEffect } from 'react'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import { Helmet } from 'react-helmet'
import { Box, Card, Container, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { HeaderBox, StyledCell, IconText } from 'src/components'
import { useApi } from 'src/util/'

const PublicInfo = () => {
  // console.log('PublicInfo: ')
  const request = useApi()
  const [record, setRecord] = useState([])
  useEffect(() => {
    request.get('/publicInfo', null, ({ body }) => setRecord(body))
  }, [])

  return (
    <>
      <Helmet>
        <title>PublicInfo | M System</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', py: 3 }}>
        <Container maxWidth="false">
          <Card>
            <HeaderBox title="監視公開情報" />
            <TableContainer>
              {/* <PerfectScrollbar> */}
              <Table sx={{ borderCollapse: 'separate' }} size="small">
                <TableHead sx={{ wordBreak: 'keep-all' }}>
                  <TableRow>
                    <StyledCell sx={{ minWidth: 90, width: 90 }} align="center">
                      監視結果
                    </StyledCell>
                    <StyledCell sx={{ minWidth: 300 }}>URL</StyledCell>
                    <StyledCell sx={{ minWidth: 200 }}>説明</StyledCell>
                    <StyledCell sx={{ minWidth: 120, width: 120 }}>監視時刻</StyledCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record.map((row) => (
                    <TableRow hover key={row.url_id} sx={{ height: 53 }}>
                      <StyledCell align="center">
                        {row.status_kbn === '成功' && <IconText.Success />}
                        {row.status_kbn === '失敗' && <IconText.Error />}
                        {row.status_kbn === '警告' && <IconText.Warning />}
                      </StyledCell>
                      <StyledCell sx={{ wordBreak: 'break-all' }}>{row.url}</StyledCell>
                      <StyledCell>{row.description}</StyledCell>
                      <StyledCell>{row.returnd_at}</StyledCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* </PerfectScrollbar> */}
            </TableContainer>
          </Card>
        </Container>
      </Box>
    </>
  )
}

export default PublicInfo
