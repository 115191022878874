import { Button, DialogActions, DialogContent, DialogContentText, Menu, MenuItem, Divider } from '@mui/material'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { TextField, Dialog, DialogText, Snackbar } from 'src/components'
import { useApi, useStateManage } from 'src/util'

const RENAME = yup.object({
  rename: yup
    .string()
    .trim()
    .required('新しいファイル名は必須項目です。')
    .test('', 'ファイル名に禁止文字（\\/:*/?"<>|;）が含まれます。', (value) => !value?.match(/[\\/:*/?"<>|;]/))
})

const RemoteDisplayMore = ({ dispState, moreMenu, setMoreMenu, handlePathClick }) => {
  // console.log('RemoteDisplayMore: ')
  const request = useApi()
  const { state, setValue } = useStateManage({ 1: false, 2: false, snackbar: false })

  const arg = moreMenu.callArg
  const path = { remote_id: dispState.remote_id, absolutePath: dispState.dirPath + arg.fileName }

  const itemList = [
    {
      itemName: '名前の変更',
      call: () => setValue(1, true)
    },
    {
      itemName: '複製',
      call: () => {
        request.post('/rmt/cmdCopyFile', path, () => handlePathClick(dispState.dirPath))
      },
      disabled: arg.fileType !== '-'
    },
    {
      itemName: '削除',
      call: () => setValue(2, true)
    },
    { itemName: 'divider1', divider: true },
    {
      itemName: 'ファイルパス (Copy)',
      call: async () => {
        await navigator.clipboard?.writeText(dispState.dirPath + arg.fileName).then()
        setValue('snackbar', true)
      }
    },
    { itemName: 'divider2', divider: true },
    {
      itemName: '圧縮',
      call: () => {
        request.post('/rmt/cmdCompress', path, () => handlePathClick(dispState.dirPath))
      }
    },
    {
      itemName: '解凍',
      call: () => {
        request.post('/rmt/cmdDecompress', path, () => handlePathClick(dispState.dirPath))
      }
    }
  ]

  // 名前の変更押下
  const form = useForm({
    defaultValues: { rename: '' },
    resolver: yupResolver(RENAME)
  })

  const onReset = () => setValue(1, false)
  const onSubmit = (values) => {
    request.patch('/rmt/cmdRename', { ...path, rename: values.rename }, () => {
      handlePathClick(dispState.dirPath)
    })
    onReset()
  }

  useEffect(() => {
    form.setValue('rename', arg.fileName)
  }, [arg.fileName])

  // 削除押下
  const sendDialog1 = () => {
    if (arg.fileType === 'd') {
      request.delete('/rmt/cmdDelDir', path, () => handlePathClick(dispState.dirPath))
    } else {
      request.delete('/rmt/cmdDelFile', path, () => handlePathClick(dispState.dirPath))
    }
    setValue(2, false)
  }

  return (
    <>
      <Menu
        open={moreMenu.open}
        anchorEl={moreMenu.anchorEl}
        onClose={() => setMoreMenu({ open: false, anchorEl: null })}
        PaperProps={{ style: { maxHeight: 220, minWidth: '20ch' } }}
      >
        {itemList.map((item) =>
          item.divider ? (
            <Divider key={item.itemName} />
          ) : (
            <MenuItem
              key={item.itemName}
              disabled={item.disabled}
              onClick={() => {
                setMoreMenu({ open: false, anchorEl: null })
                item.call()
              }}
            >
              {item.itemName}
            </MenuItem>
          )
        )}
      </Menu>

      <Dialog title="ファイル名の変更" open={state[1]} close={onReset}>
        <DialogContent>
          <DialogText sx={{ mb: 2 }}>{'「' + arg.fileName + '」の名前を変更します。'}</DialogText>
          <TextField form={form} name="rename" autoFocus label="新しいファイル名*" />
        </DialogContent>
        <DialogActions>
          <Button onClick={onReset}>キャンセル</Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
            送信
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        title={arg.fileType === 'd' ? 'フォルダの削除' : 'ファイルの削除'}
        open={state[2]}
        close={() => setValue(2, false)}
      >
        <DialogContent>
          <DialogText>{'「' + arg.fileName + '」を削除します。'}</DialogText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setValue(2, false)}>キャンセル</Button>
          <Button onClick={() => sendDialog1()}>送信</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        message="クリップボードにコピーしました。"
        open={state.snackbar}
        onClose={() => setValue('snackbar', false)}
      />
    </>
  )
}

RemoteDisplayMore.propTypes = {
  dispState: PropTypes.object,
  moreMenu: PropTypes.object,
  setMoreMenu: PropTypes.func,
  handlePathClick: PropTypes.func
}

export default RemoteDisplayMore
