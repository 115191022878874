import { Box, Slide, Typography } from '@mui/material'
import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

export const SlideBox = ({ state, no, sx, children, ...props }) => {
  const elem = useRef(null)
  const direction = state.activeStep === no ? (state.back ? 'right' : 'left') : state.back ? 'left' : 'right'

  // console.log('no:', no, 'activeStep:', state.activeStep, 'back:', state.back, 'direction:', direction)

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (state.activeStep !== no || !elem.current) return
      console.log('ResizeObserver:' + no)
      elem.current.parentElement.style.height = elem.current.clientHeight + 'px'
    })
    observer.observe(elem.current)
  }, [state.activeStep])

  return (
    <Slide direction={direction} in={state.activeStep === no}>
      <Box ref={elem} {...props} sx={{ position: 'absolute ', width: '100%', ...sx }}>
        {children}
      </Box>
    </Slide>
  )
}

SlideBox.propTypes = {
  state: PropTypes.object,
  no: PropTypes.number,
  children: PropTypes.any,
  sx: PropTypes.object
}
