import { Box, TableBody, TableRow } from '@mui/material'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledCell, StyledLinkCell, Dialog, RenderingSuppress, MoreIconButton } from 'src/components'
import { useApi, downloadBlob, useStateManage } from 'src/util/'

const CrlDialog = ({ open, onClose, callArg = {} }) => {
  console.log('CrlDialog')
  const request = useApi()
  const [state, setState] = useState({})

  const handleDetailText = () => {
    if (!open) return
    request.get('/ejbcas/crlDetail', { ejbca_id: callArg.ejbca_id, issuer_dn: callArg.ca_subject_dn }, ({ body }) => {
      setState(body)
    })
  }
  useEffect(handleDetailText, [open])

  return (
    <Dialog
      title={`CRL詳細（${callArg.ca_subject_dn}）`}
      maxWidth="md"
      open={open}
      close={() => {
        onClose()
        setState({})
      }}
    >
      <Box sx={{ fontSize: '13px', color: '#24292f', maxHeight: '720px', p: '8px', overflow: 'scroll' }}>
        <pre>{state.crlText}</pre>
      </Box>
    </Dialog>
  )
}

CrlDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  callArg: PropTypes.object
}

const CertTableBody = ({ list }) => {
  const { state, setValue } = useStateManage({ 1: {}, 2: {} })
  const request = useApi()

  const handleDownload = (callArg) => {
    request.get(
      '/ejbcas/certDownload',
      { ejbca_id: callArg.ejbca_id, fingerprint: callArg.fingerprint },
      ({ body }) => {
        if (body.size) downloadBlob(`${callArg.fingerprint}.pem`, body)
      }
    )
  }

  const itemList = [
    { itemName: 'CRL(失効リスト)詳細', call: (callArg) => setValue(1, { open: true, callArg }) }
    // { itemName: 'CRLダウンロード', call: (callArg) => handleDownload(callArg) },
  ]

  return (
    <TableBody>
      {list.map((row, index) => (
        <TableRow hover key={`${row.remote_name}${row.host_name}${row.ca_name}${row.timeStamp}`}>
          {/* <StyledCell sx={{ wordBreak: 'break-all', cursor: 'pointer', '&:hover': { backgroundColor: '#e8f0fe' } }}> */}
          <StyledLinkCell sx={{ wordBreak: 'break-all' }} to={`/app/ejbcaData/certList?ejbca_id=${row.ejbca_id}`}>
            {row.remote_name}
          </StyledLinkCell>
          <StyledLinkCell sx={{ wordBreak: 'break-all' }} to={`/app/ejbcaData/certList?ejbca_id=${row.ejbca_id}`}>
            {row.host_name}
          </StyledLinkCell>
          <StyledLinkCell
            sx={{ wordBreak: 'break-all' }}
            to={`/app/ejbcaData/certList?ejbca_id=${row.ejbca_id}&ca_id=${row.ca_id}`}
          >
            {row.ca_name}
          </StyledLinkCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>
            累計:{row.cert_cnt}（有効:{row.cert_active_cnt}, 失効:{row.cert_revoked_cnt}, 無効:{row.cert_archived_cnt}）
          </StyledCell>
          <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.sync_at}</StyledCell>
          <StyledCell align="center" sx={{ p: '0', height: '42px' }}>
            <MoreIconButton itemList={itemList} callArg={row} icon="horiz" sx={{ m: '-8px' }} />
          </StyledCell>
        </TableRow>
      ))}
      <RenderingSuppress show={state[1].open}>
        <CrlDialog open={state[1].open} onClose={() => setValue([1, 'open'], false)} callArg={state[1].callArg} />
      </RenderingSuppress>
    </TableBody>
  )
}

CertTableBody.propTypes = {
  list: PropTypes.array.isRequired
}

export default CertTableBody
