import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { Pagination, SvgIcon, IconButton } from '@mui/material'

const UrlPagination = ({ pageCount, pageNo, onChange, onToggle }) => {
  if (!pageCount) return <></>
  return (
    <>
      <Pagination
        color="primary"
        count={pageCount}
        size="small"
        page={pageNo}
        onChange={(e, page) => {
          if (page) onChange(page)
        }}
      />
      {/* <IconButton color="primary" size="small" sx={{ p: 0 }} onClick={onToggle}>
        <SvgIcon color="primary" variant="contained">
          <SwapVertIcon />
        </SvgIcon>
      </IconButton> */}
    </>
  )
}

UrlPagination.propTypes = {
  pageCount: PropTypes.number,
  pageNo: PropTypes.number,
  onChange: PropTypes.func,
  onToggle: PropTypes.func
}

export default UrlPagination
