/* eslint-disable */
import { useOutletContext } from 'react-router-dom'
import { deleteNoValue } from './commons'
import { setLoading } from 'src/components/show/Loading'

/**
 * メッセージオブジェクトを生成します。
 * @param {'success' | 'info' | 'warning' | 'error'} severity
 * @param {string} body
 * @param {boolean} open
 * @returns Object
 */
export const createMessage = (severity, body, open) => {
  return { severity, body, open: open || true }
}

export const createUser = () => {
  return {
    id: undefined,
    name: undefined,
    loginStatus: 0,
    token: undefined,
    contractId: undefined
  }
}

export const createRespData = () => {
  return {
    user: createUser(),
    body: undefined,
    error: null, // resp.status + ': ' + resp.statusText
    messages: []
  }
}

const respLocation = [location.pathname]
const checkMessageCleare = (option, global) => {
  if (option.cleareMessage) {
    global.messages = []
    return
  }
  const previous = respLocation.shift()
  respLocation.push(location.pathname)
  if (previous !== location.pathname) {
    global.messages = []
  }
}

const _createRespData = async (resp, user) => {
  let respData = createRespData()
  if (!resp.ok) respData.error = resp.status + ': ' + resp.statusText

  respData.user = JSON.parse(decodeURIComponent(resp.headers.get('x-user'))) || {}
  const type = resp.headers.get('Content-Type')
  // for (const item of resp.headers.values()) { console.log(item)}
  if (type) {
    if (type.startsWith('application/json')) {
      const json = await resp.json()
      respData.body = json
    } else if (type.startsWith('application/')) {
      respData.body = await resp.blob()
      const xWarnMsg = resp.headers.get('x-warn-msg') && decodeURIComponent(resp.headers.get('x-warn-msg'))
      if (xWarnMsg) respData.messages.push(createMessage('warning', xWarnMsg))
    }
  }

  if (!resp.ok) {
    if (respData.body) {
      if (respData.body.type !== 'LoginError') {
        respData.messages.push(createMessage('error', respData.body.type + ': ' + respData.body.message))
      }
    } else {
      respData.messages.push(createMessage('error', 'APIへの接続でエラーが発生しました。' + respData.error))
    }
  }

  if (respData.user.loginStatus === 2) {
    respData.messages.push(createMessage('success', 'ログアウトしました。'))
  } else if (respData.user.loginStatus === 9) {
    respData.messages.push(createMessage('warning', 'セッションが取得できなかったため、ログアウトしました。'))
  } else if (resp.status === 504 || resp.status === 403) {
    respData.user.loginStatus = 9
  }
  // if (user.id !== respData.user.id && location.pathname !== '/login') {
  //   // 同一ブラウザ、複数タブでユーザ切替。公開URLにリクエスト時
  //   alert('ログインユーザIDが切り替えられました。ページを再読み込みします。')
  //   location.reload()
  // }
  return respData
}

export const useApi = (option = {}) => {
  let [global, render] = option.global && option.render ? [option.global, option.render] : useOutletContext()

  const returnProcess = async (respData, success, error) => {
    // 各メッセージとユーザ情報の反映
    checkMessageCleare(option, global)
    respData.messages.forEach((message) => {
      const finded = global.messages.find((el) => JSON.stringify(el) === JSON.stringify(message))
      if (!finded) global.messages.push(message)
    })
    global.user = { ...global.user, ...respData.user }
    render()
    // 強制ログアウト遷移
    if (respData.user.loginStatus >= 2) {
      const logout = document.getElementById('logout')
      if (logout) logout.click()
      return
    }
    // コールバックがあれば実行
    if (!respData.error) {
      if (success) success(respData)
    } else {
      if (error && typeof error === 'function' && respData.body) error(respData)
    }
    // スクロールトップ
    if (global.messages.length) {
      const contents = document.getElementById('contents')
      contents && contents.scrollTo(0, 0)
    }
  }

  const fetchApi = async (method, path, sendData, option) => {
    const headers = { location: location.pathname + location.search, token: '' }
    if (global.user.token) {
      headers.token = global.user.token
    }
    if (option?.json) {
      headers['Content-Type'] = 'application/json'
      if (sendData) sendData = JSON.stringify(sendData)
    }
    const result = await fetch('/api' + path, {
      method: method,
      credentials: 'same-origin',
      headers: headers,
      body: sendData
    })
    return result
  }

  const wrap = async (method, path, sendData, success, error) => {
    console.log('-', method, '/api' + path)
    let response, loadingName
    try {
      if (option.loading) {
        loadingName = option.loading
        await setLoading(loadingName, true)
        option.loading = undefined
      }
      if (method === 'GET') {
        let query = ''
        const sendQuery = deleteNoValue({ ...sendData })
        if (sendQuery) query = '?' + new URLSearchParams(sendQuery)
        response = await fetchApi('GET', path + query, null, { json: true })
      } else if (method === 'UPLOAD') {
        response = await fetchApi('POST', path, sendData)
      } else {
        response = await fetchApi(method, path, sendData, { json: true })
      }
      const respData = await _createRespData(response, global.user)
      await returnProcess(respData, success, error)
      return respData.body
    } catch (e) {
      console.error('APIリクエストエラー!!', e)
      const respData = createRespData()
      respData.messages.push(createMessage('error', 'APIへのリクエストに失敗しました。' + e.message))
      respData.user.loginStatus = 9
      await returnProcess(respData)
    } finally {
      if (loadingName) await setLoading(loadingName, false)
    }
  }

  const request = {
    /** @param {string} path  @param {object} sendData @param {function} success @param {function} error @return {Promise<object>} */
    get: (path, sendData, success, error) => wrap('GET', path, sendData, success, error),

    /** @param {string} path  @param {object} sendData @param {function} success @param {function} error @return {Promise<object>} */
    post: (path, sendData, success, error) => wrap('POST', path, sendData, success, error),

    /** @param {string} path  @param {object} sendData @param {function} success @param {function} error @return {Promise<object>} */
    patch: (path, sendData, success, error) => wrap('PATCH', path, sendData, success, error),

    /** @param {string} path  @param {object} sendData @param {function} success @param {function} error @return {Promise<object>} */
    put: (path, sendData, success, error) => wrap('PUT', path, sendData, success, error),

    /** @param {string} path  @param {object} sendData @param {function} success @param {function} error @return {Promise<object>} */
    delete: (path, sendData, success, error) => wrap('DELETE', path, sendData, success, error),

    /** @param {string} path  @param {object} sendData @param {function} success @param {function} error @return {Promise<object>} */
    upload: (path, formData, success, error) => wrap('UPLOAD', path, formData, success, error),

    setOption: (_option) => (option = { ...option, ..._option })
  }
  return request
}
