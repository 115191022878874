import PropTypes from 'prop-types'
import {
  TextField as TextFieldMui,
  IconButton,
  InputAdornment,
  Checkbox as CheckboxMui,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
// import { styled } from '@mui/material/styles'
import { useState, useEffect } from 'react'
import { Controller } from 'react-hook-form'

export const TextField = ({ form, name, children, ...props }) => {
  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field: { ref, ...field } }) => (
        <StyledTextField
          id={name}
          // error={props.error || name in form.formState.errors}
          error={props.error || Boolean(form.formState.errors[name])}
          helperText={props.helperText || form.formState.errors[name]?.message}
          fullWidth
          autoComplete={props.autoComplete || 'off'}
          inputProps={props.inputProps}
          // {...register(name)}
          inputRef={ref}
          {...field}
          {...props}
        >
          {children}
        </StyledTextField>
      )}
    />
  )
}
TextField.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.any,
  error: PropTypes.bool,
  helperText: PropTypes.any,
  autoComplete: PropTypes.string,
  inputProps: PropTypes.object
}

export const TextPassword = ({ form, name, ...props }) => {
  const [showPassword, setShow] = useState(false)
  const [selectionEnd, setSelectionEnd] = useState(0)

  const _setShow = () => {
    setSelectionEnd(document.getElementsByName(name)[0].selectionEnd)
    setShow(!showPassword)
  }

  useEffect(() => {
    document.getElementsByName(name)[0].setSelectionRange(selectionEnd, selectionEnd)
  }, [showPassword])

  return (
    <TextField
      form={form}
      name={name}
      {...props}
      type={showPassword ? 'text' : 'password'}
      autoComplete="off"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" color="inherit">
            <IconButton
              size="small"
              aria-label="toggle password visibility"
              onClick={_setShow}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
              tabIndex={-1}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}
TextPassword.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  inputProps: PropTypes.object
}

export const StyledTextField = ({ children, ...props }) => {
  if (!props.size) {
    props.size = 'short'
    props.sx = {
      '& .MuiOutlinedInput-input, .MuiSelect-select': {
        paddingTop: '12px',
        paddingBottom: '12px'
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(12px, 12px) scale(1)'
      },
      '& .MuiInputLabel-shrink': {
        userSelect: 'none',
        pointerEvents: 'auto',
        maxWidth: 'calc(133% - 32px)',
        transform: 'translate(14px, -9px) scale(0.75)'
      },
      ...props.sx
    }
  }
  return <TextFieldMui {...props}>{children}</TextFieldMui>
}
StyledTextField.propTypes = {
  size: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.any
}

// // TODO Dialog利用時にスクロールバーが出る。overflow:'hidden'で対応
// export const StyledTextField = styled(TextField)(
//   ({ size, InputLabelProps = {}, value, inputProps = {}, ref, ...rest }) => (
//     !size && {
//       '& .MuiFormLabel-root': !InputLabelProps.shrink && {
//         // transform: value === '' ? 'translate(14px, 12px)' : 'translate(14px, -9px) scale(0.75)'
//         transform: (!value || value === '') && (inputProps.value === undefined || inputProps.value === '')
//           ? 'translate(14px, 12px)'
//           : 'translate(14px, -9px) scale(0.75)'
//       },
//       '& label': {
//         '&.Mui-focused': {
//           transform: 'translate(14px, -9px) scale(0.75)'
//         }
//       },
//       '& .MuiOutlinedInput-input, .MuiSelect-select': {
//         paddingTop: 12.5,
//         paddingBottom: 12.5,
//       }
//     }
//   )
// )

export const Checkbox = ({ form, name, label, ...props }) => {
  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field: { ref, ...field } }) => (
        <FormControl error={props.error || Boolean(form.formState.errors[name])}>
          <FormControlLabel
            label={label}
            control={<CheckboxMui id={name} checked={form.getValues(name)} {...field} {...props} />}
          />
          <FormHelperText sx={{ mx: 0 }}>{props.helperText || form.formState.errors[name]?.message}</FormHelperText>
        </FormControl>
      )}
    />
  )
}
Checkbox.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.any
}
