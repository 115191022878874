import { useState, useEffect } from 'react'
import { Card, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledCell, HeaderBox } from 'src/components'
import { useApi } from 'src/util/'

const LoginHistory = () => {
  console.log('LoginHistory: ')
  const request = useApi()
  const [record, setRecord] = useState([])
  useEffect(() => {
    request.get('/myLoginHistory', null, ({ body }) => setRecord(body))
  }, [])

  return (
    <Card>
      <HeaderBox title="直近のログイン履歴" />
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <StyledCell>ログイン時間</StyledCell>
              <StyledCell>IPアドレス</StyledCell>
              <StyledCell sx={{ minWidth: 450 }}>ユーザエージェント</StyledCell>
              <StyledCell>認証失敗回数</StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.map((row) => (
              <TableRow hover key={row.logind_at}>
                <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.logind_at}</StyledCell>
                <StyledCell>{row.ip}</StyledCell>
                <StyledCell sx={{ wordBreak: 'break-all' }}>{row.user_agent}</StyledCell>
                <StyledCell>{row.login_mismatched_count}</StyledCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default LoginHistory
