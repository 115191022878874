import { Box, Card, Grid, TextField, MenuItem, SvgIcon } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useApi } from 'src/util/'
import AuthGroupMore from './AuthGroupMore'

const AuthGroup = ({ selectedGroup, setSelectedGroup }) => {
  // console.log('AuthGroup')
  const request = useApi()
  const [authGroupList, setAuthGroupList] = useState([])

  const handleSelected = ({ target, _authGroupList = authGroupList }) => {
    const selected = _authGroupList.find((item) => item.auth_group_id === target.value)
    setSelectedGroup({
      auth_group_id: selected.auth_group_id,
      auth_group_name: selected.auth_group_name,
      description: selected.description
    })
  }

  const init = (option) => {
    request.get('/authGroup/list', null, ({ body }) => {
      setAuthGroupList(body)
      if (option && option.createdFlg) {
        handleSelected({
          target: { value: body[body.length - 1].auth_group_id },
          _authGroupList: body
        })
      }
    })
  }
  useEffect(init, [])

  return (
    <Card sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <Box
        py={1}
        px={2}
        sx={{ minHeight: '64px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <SvgIcon color="action">
            <SearchIcon />
          </SvgIcon>
          <TextField
            select
            fullWidth
            size="small"
            label="権限グループの選択"
            name="authGroup"
            onChange={handleSelected}
            sx={{ mx: 1 }}
            value={selectedGroup.auth_group_id}
          >
            {authGroupList.map((item) => (
              <MenuItem key={item.auth_group_id} value={item.auth_group_id}>
                {item.auth_group_name}
                {item.description && '（' + item.description + '）'}
              </MenuItem>
            ))}
          </TextField>
          <AuthGroupMore init={init} selectedGroup={selectedGroup} />
        </Grid>
      </Box>
    </Card>
  )
}

AuthGroup.propTypes = {
  selectedGroup: PropTypes.object,
  setSelectedGroup: PropTypes.func
}

export default AuthGroup
