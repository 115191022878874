import { Box, Card, Grid, MenuItem, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import RemoteSelectMore from './RemoteSelectMore'

const RemoteSelect = ({ conn, ...props }) => {
  console.log('RemoteSelect', conn.selectedKey)

  const handleSelected = ({ target }) => {
    conn.selectedKey = target.value
    conn.drow()
  }

  return (
    <Card sx={{ display: 'flex', flexFlow: 'column', height: '100%' }} {...props}>
      <Box
        py={1}
        px={2}
        sx={{ minHeight: '64px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          {/* <SvgIcon color="action"><SearchIcon /></SvgIcon> */}
          {/* <Box sx={{ wordBreak: 'keep-all' }}>ログイン:</Box> */}
          <TextField
            select
            size="small"
            fullWidth
            sx={{ mx: 1 }}
            label="リモート接続先の選択"
            name="connectionConfig"
            onChange={handleSelected}
            value={conn.selectedKey}
          >
            {conn.list.map((item) => (
              <MenuItem key={item.remote_id} value={item.remote_id}>
                {item.remote_name + '（' + item.host + ':' + item.port + '）'}
                {item.description && item.description}
              </MenuItem>
            ))}
          </TextField>
          <RemoteSelectMore conn={conn} />
        </Grid>
      </Box>
    </Card>
  )
}

RemoteSelect.propTypes = {
  conn: PropTypes.object
}

export default RemoteSelect
