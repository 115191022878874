import React from 'react'
import { Container, Grid } from '@mui/material'
import AccountSearch from 'src/contents/acmeData/account/AccountSearch'
import AccountTable from 'src/contents/acmeData/account/AccountTable'
import { MainProvider } from 'src/util/'
import { MainContent } from 'src/components'

const AcmeAccount = () => {
  console.log('AcmeAccount')

  return (
    <MainContent title="AcmeAccount | M System">
      <MainProvider>
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AccountSearch />
            </Grid>
            <Grid item xs={12}>
              <AccountTable />
            </Grid>
          </Grid>
        </Container>
      </MainProvider>
    </MainContent>
  )
}

export default AcmeAccount
