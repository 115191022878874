import { Helmet } from 'react-helmet'
import { Alert, Box } from '@mui/material'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
// import { Alert } from 'src/components/show/Alert'
import PropTypes from 'prop-types'

export const MainContent = ({ title, children, ...props }) => {
  const [global] = useOutletContext()
  const [allowView, setAllowView] = useState(true)

  if (!global.allowedMenuPaths[window.location.pathname] && global.dispMenus.length !== 0 && allowView) {
    setAllowView(false)
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', py: 3 }} {...props}>
        {allowView ? (
          children
        ) : (
          <Alert severity="error" sx={{ my: -3 }}>
            許可されていないURLです。
          </Alert>
        )}
      </Box>
    </>
  )
}

MainContent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
}
