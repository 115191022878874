import { Helmet } from 'react-helmet'
import { Box, Container, Typography } from '@mui/material'

const NotFound = () => (
  <>
    <Helmet>
      <title>404 | M System</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="md">
        <Typography align="center" color="textPrimary" variant="h1">
          404: The page you are looking for isn’t here
        </Typography>
        <Box sx={{ textAlign: 'center', fontSize: 'xxx-Large', py: 2 }}>
          <Typography align="center" color="textPrimary" variant="subtitle1">
            お探しのページはここにありません。
          </Typography>
          <Typography color="textPrimary" fontSize="2em" variant="h1">
            4 0 4
          </Typography>
        </Box>
      </Container>
    </Box>
  </>
)

export default NotFound
