import { Button, DialogActions, DialogContent, IconButton } from '@mui/material'
import { CreateNewFolderOutlined as CreateNewFolderIcon } from '@mui/icons-material'
import { useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Dialog, DialogText, TextField } from 'src/components'
import { useApi } from 'src/util/'

const NEW_DIR = yup.object({
  dirName: yup
    .string()
    .trim()
    .required('新しいフォルダ名は必須項目です。')
    .test('', 'フォルダ名に禁止文字（\\/:*/?"<>|;）が含まれます。', (value) => !value?.match(/[\\/:*/?"<>|;]/))
})

const MakeNewDir = ({ dispState, handlePathClick }) => {
  console.log('MakeNewDir')
  const request = useApi()
  const [open, setOpen] = useState(false)

  const form = useForm({
    defaultValues: { dirName: '新しいフォルダ' },
    resolver: yupResolver(NEW_DIR)
  })

  const onSubmit = (values) => {
    request.post(
      '/rmt/cmdMakeDir',
      {
        remote_id: dispState.remote_id,
        absolutePath: dispState.dirPath,
        dirName: values.dirName
      },
      () => {
        handlePathClick(dispState.dirPath)
      }
    )
    setOpen(false)
  }

  return (
    <>
      <IconButton size="small" sx={{ ml: 1, mr: 1 }} component="span" onClick={() => setOpen(true)}>
        <CreateNewFolderIcon />
      </IconButton>

      <Dialog title="フォルダの作成" open={open} close={() => setOpen(false)}>
        <DialogContent>
          <DialogText sx={{ mb: 2 }}>フォルダを作成します。</DialogText>
          <TextField form={form} name="dirName" autoFocus label="新しいフォルダ名*" />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>キャンセル</Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
            送信
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

MakeNewDir.propTypes = {
  dispState: PropTypes.object,
  handlePathClick: PropTypes.func
}

export default MakeNewDir
