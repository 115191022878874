import { Box, Button, DialogActions, DialogContent, Divider, Grid, TableBody, TableRow, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  StyledCell,
  Dialog,
  DialogText,
  LoadButton,
  RenderingSuppress,
  MoreIconButton,
  TextField
} from 'src/components'
import { useApi, downloadBlob, setFormInit, useStateManage } from 'src/util/'

const CertDetail = ({ open, onClose, ejbca_id, callArg = {} }) => {
  console.log('CertDetail')
  const request = useApi()
  const [state, setState] = useState({})

  const handleDetailText = () => {
    if (!open) return
    request.get('/ejbcas/certDetail/text', { ejbca_id, fingerprint: callArg.fingerprint }, ({ body }) => {
      setState(body)
    })
  }
  useEffect(handleDetailText, [open])

  return (
    <Dialog title={`証明書詳細（${callArg.subject_dn}）`} maxWidth="md" open={open} close={onClose}>
      <Box sx={{ fontSize: '13px', color: '#24292f', maxHeight: '720px', p: '8px', overflow: 'scroll' }}>
        <pre>
          fingerprint: {callArg.fingerprint}
          <br />
          <Divider sx={{ my: '8px' }} />
          {state.x509Text}
          <Divider sx={{ my: '8px' }} />
          {state.x509Purpose}
        </pre>
      </Box>
    </Dialog>
  )
}

CertDetail.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ejbca_id: PropTypes.number,
  callArg: PropTypes.object
}

const REASONS = [
  { key: 0, value: '未指定' },
  { key: 1, value: '鍵危殆化' },
  { key: 2, value: 'CA危殆化' },
  { key: 3, value: '所属変更' },
  { key: 4, value: '取替' },
  { key: 5, value: '運用停止' },
  { key: 6, value: '保留' },
  { key: 8, value: 'CRLから削除' },
  { key: 9, value: '権限取消' },
  { key: 10, value: 'AA危殆化' }
]

const CertRevoke = ({ open, onClose, ejbca_id, callArg = {} }) => {
  console.log('CertRevoke')
  const request = useApi()

  const form = useForm({
    defaultValues: { ejbca_id: '', ca_name: '', fingerprint: '', reason: '' },
    resolver: yupResolver(
      yup.object({
        reason: yup.string().trim().required('失効理由は必須項目です。')
      })
    )
  })

  const onSubmit = async (values) => {
    await request.post('/ejbcas/certRevoke', values, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const close = { dialog: onClose, form: form.reset }

  const initForm = () => {
    if (!open) return
    setFormInit(form, {
      ejbca_id,
      ca_name: callArg.ca_name,
      fingerprint: callArg.fingerprint
    })
    // form.trigger('_render')
  }
  useEffect(initForm, [open])

  return (
    <Dialog title={`証明書失効（${callArg.subject_dn}）`} maxWidth="md" open={open} close={close}>
      <DialogContent sx={{ p: 2 }}>
        <DialogText sx={{ mb: 2 }}>失効理由を選択してください。</DialogText>
        <Grid item xs={12}>
          <TextField select form={form} name="reason" label="失効理由">
            {REASONS.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            close.dialog()
            close.form()
          }}
        >
          キャンセル
        </Button>
        <LoadButton type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </LoadButton>
      </DialogActions>
    </Dialog>
  )
}

CertRevoke.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ejbca_id: PropTypes.number,
  callArg: PropTypes.object
}

const CertTableBody = ({ ejbca_id, list }) => {
  const { state, setValue } = useStateManage({ 1: {}, 2: {} })
  const request = useApi()

  const handleDownload = (callArg) => {
    request.get('/ejbcas/certDownload', { ejbca_id, fingerprint: callArg.fingerprint }, ({ body }) => {
      if (body.size) downloadBlob(`${callArg.fingerprint}.pem`, body)
    })
  }

  const itemList = [
    { itemName: '証明書詳細', call: (callArg) => setValue(1, { open: true, callArg }) },
    { itemName: '証明書ダウンロード', call: (callArg) => handleDownload(callArg) },
    { itemName: '証明書失効', call: (callArg) => setValue(2, { open: true, callArg }) }
  ]

  return (
    <TableBody>
      {list.map((row, index) => (
        <TableRow hover key={`${row.fingerprint}`}>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.ca_name}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.issuer_dn}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.subject_dn}</StyledCell>
          <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.expire_date_d}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>
            {row.status}（
            {row.status === 20 ? '有効' : row.status === 40 ? row.revocation : row.status === 60 ? '保存' : ''}）
          </StyledCell>
          <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.update_time_d}</StyledCell>
          <StyledCell align="center" sx={{ p: '0', height: '42px' }}>
            <MoreIconButton itemList={itemList} callArg={row} icon="horiz" sx={{ m: '-8px' }} />
          </StyledCell>
        </TableRow>
      ))}
      <RenderingSuppress show={state[1].open}>
        <CertDetail
          open={state[1].open}
          onClose={() => setValue([1, 'open'], false)}
          ejbca_id={ejbca_id}
          callArg={state[1].callArg}
        />
      </RenderingSuppress>
      <RenderingSuppress show={state[2].open}>
        <CertRevoke
          open={state[2].open}
          onClose={() => setValue([2, 'open'], false)}
          ejbca_id={ejbca_id}
          callArg={state[2].callArg}
        />
      </RenderingSuppress>
    </TableBody>
  )
}

CertTableBody.propTypes = {
  ejbca_id: PropTypes.number,
  list: PropTypes.array.isRequired
}

export default CertTableBody
