import { Box, Button, Card, CardContent, Divider, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { AutocompleteText, DownloadButton, HeaderBox, FooterBox, StyledTextField } from 'src/components'

const PERIOD = {
  TODAY: { no: 10, name: '今日' },
  WEEK: { no: 20, name: '1週間' },
  MONTH: { no: 30, name: '1カ月' },
  ALL: { no: 40, name: 'すべて' },
  CUSTOM: { no: 50, name: 'カスタム' }
}

export const initialState = () => {
  return {
    period: PERIOD.WEEK.no,
    startDate: dayjs().add(-7, 'days').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    statusKbn: '',
    message: '',
    url: ''
  }
}

export const AggregateSearch = ({ form, setFormElem, onClickAggregate }) => {
  console.log('AggregateSearch')
  const handleChange = (event) => {
    setFormElem({ [event.target.name]: event.target.value })
  }
  const handleChangeDate = (event) => {
    setFormElem({ period: PERIOD.CUSTOM.no, [event.target.name]: event.target.value })
  }

  const handleChangePeriod = (event) => {
    const periodVal = event.target.value
    let endDateVal = dayjs().format('YYYY-MM-DD')
    let startDateVal
    if (PERIOD.TODAY.no === periodVal) {
      startDateVal = endDateVal
    } else if (PERIOD.WEEK.no === periodVal) {
      startDateVal = dayjs().add(-7, 'days').format('YYYY-MM-DD')
    } else if (PERIOD.MONTH.no === periodVal) {
      startDateVal = dayjs().add(-1, 'months').format('YYYY-MM-DD')
    } else if (PERIOD.ALL.no === periodVal) {
      endDateVal = ''
      startDateVal = ''
    }

    setFormElem({
      period: periodVal,
      startDate: startDateVal,
      endDate: endDateVal
    })
  }

  const submitForm = (event) => {
    event.preventDefault()
    onClickAggregate(1)
  }

  useEffect(() => onClickAggregate(1), [])

  return (
    <form autoComplete="off" onSubmit={submitForm} style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <HeaderBox title="集計条件" sx={{ minHeight: '54px' }}>
          <FormControl>
            <InputLabel id="select-label-1">期間設定</InputLabel>
            <Select
              labelId="select-label-1"
              label="期間設定"
              name="period"
              value={form.period}
              size="small"
              sx={{ minWidth: 120, my: '-2px' }}
              onChange={handleChangePeriod}
            >
              {Object.keys(PERIOD).map((row) => (
                <MenuItem key={PERIOD[row].no} value={PERIOD[row].no}>
                  {PERIOD[row].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </HeaderBox>
        <Divider />
        <CardContent>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <StyledTextField
                name="startDate"
                label="開始日"
                type="date"
                value={form.startDate}
                onChange={handleChangeDate}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <StyledTextField
                name="endDate"
                label="終了日"
                type="date"
                value={form.endDate}
                onChange={handleChangeDate}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <StyledTextField
                  select
                  fullWidth
                  label="ステータス区分"
                  name="statusKbn"
                  onChange={handleChange}
                  value={form.statusKbn}
                  // SelectProps={{ native: true }}
                  // InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="">{'　'}</MenuItem>
                  <MenuItem value="成功">成功</MenuItem>
                  <MenuItem value="失敗">失敗</MenuItem>
                  <MenuItem value="警告">警告</MenuItem>
                </StyledTextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={9}>
              <StyledTextField
                fullWidth
                label="メッセージ"
                name="message"
                onChange={handleChange}
                value={form.message}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteText
                label="URL（前方一致）"
                name="url"
                setValue={(_name, _value) => {
                  setFormElem({ [_name]: _value })
                }}
                value={form.url || ''}
                reqParams={{ method: 'get', path: '/moni/mstUrl/list' }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <FooterBox py={1.5}>
          <DownloadButton
            fileName={dayjs().format('YYYYMMDD_HHmmss') + '.csv'}
            label="CSVダウンロード"
            reqParams={{ method: 'get', path: '/moni/aggregate/csv', sendData: form }}
            afterClick={submitForm}
          />
          <Button type="submit" color="primary" variant="contained">
            集計
          </Button>
        </FooterBox>
      </Card>
    </form>
  )
}

AggregateSearch.propTypes = {
  form: PropTypes.object,
  setFormElem: PropTypes.func,
  onClickAggregate: PropTypes.func
}

export default AggregateSearch
