import { memo, useState, useRef } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { circularProgressClasses } from '@mui/material/CircularProgress'
import PropTypes from 'prop-types'

// MEMO https://mui.com/material-ui/react-backdrop/

export const setLoading = async (name, value, delay) => {
  // 事前のLoadingタグをCSSクラスで表示する。
  const elem = document.getElementById(name)
  if (!elem) return
  if (value === true) {
    elem.classList.add('load-show')
    elem.classList.remove('load-hide')
  } else {
    elem.classList.remove('load-show')
    elem.classList.add('load-hide')
  }
  if (delay) await new Promise((resolve) => setTimeout(resolve, delay))
}

export const Loading = memo(({ name, sx, initialDisplay, ...props }) => {
  // console.log('Loading')

  return (
    <Box
      id={name}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.2s all',
        zIndex: initialDisplay ? 1000 : -1,
        opacity: initialDisplay ? 1 : 0
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{ color: '#ddd', position: 'absolute', top: '8px', ...sx }}
        size={20}
        value={100}
        {...props}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        size={20}
        sx={{
          position: 'absolute',
          top: '8px',
          animationDuration: '550ms',
          [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round' },
          ...sx
        }}
        {...props}
      />
    </Box>
  )
})

Loading.propTypes = {
  name: PropTypes.string,
  sx: PropTypes.object,
  initialDisplay: PropTypes.bool
}
