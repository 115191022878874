import { Button, DialogActions, DialogContent, MenuItem, IconButton, ListItemText } from '@mui/material'
import { BookmarkBorder as BookmarkBorderIcon, Clear as ClearIcon } from '@mui/icons-material'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogText, StyledTextField, IconText } from 'src/components'
import { useApi } from 'src/util/'

const Bookmark = ({ dispState, handlePathClick }) => {
  console.log('Bookmark')
  const request = useApi()
  const [open, setOpen] = useState(false)
  const [state, setState] = useState({ selected: '', list: [], registed: false })

  const listBookmark = () => {
    console.log('api: listBookmark')
    request.get('/rmt/bookmark/list', { remote_id: dispState.remote_id }, ({ body }) => {
      let registed = false
      const list = [{ remote_id: dispState.remote_id, path: dispState.dirPath }].concat(
        body.filter((elem) => {
          if (elem.path === dispState.dirPath) {
            registed = true
            return false
          }
          return true
        })
      )
      setState({ selected: list[0].path, list, registed })
      setOpen(true)
    })
  }

  const move = (e) => {
    handlePathClick(e.target.value)
    setOpen(false)
  }

  const registerBookmark = () => {
    request.post('/rmt/bookmark', { remote_id: dispState.remote_id, absolutePath: dispState.dirPath }, () => {
      setState({ ...state, ...{ registed: true } })
    })
  }

  const clearBookmark = (e) => {
    // e.preventDefault()
    e.stopPropagation()
    request.delete('/rmt/bookmark', { remote_id: dispState.remote_id, absolutePath: e.currentTarget.title }, () => {
      listBookmark()
    })
  }

  return (
    <>
      <IconButton size="small" sx={{ ml: 1, mr: 1 }} component="span" onClick={listBookmark}>
        <BookmarkBorderIcon />
      </IconButton>

      <Dialog title="ブックマーク" open={open} close={() => setOpen(false)} maxWidth="md">
        <DialogContent>
          <DialogText sx={{ mb: 2 }}>共有ブックマークに登録、または移動します。</DialogText>

          <StyledTextField
            select
            name="bookmark"
            label="ブックマークリスト"
            fullWidth
            autoFocus
            InputLabelProps={{ shrink: true }}
            value={state.selected}
            onChange={move}
            SelectProps={{
              renderValue: (opt) => opt
            }}
          >
            {/* <MenuItem key={0} value={dispState.dirPath}>{dispState.dirPath}</MenuItem> */}
            {state.list.map((item, i) => (
              <MenuItem key={item.path} value={item.path}>
                <ListItemText>{item.path}</ListItemText>
                {(state.registed || item.path !== dispState.dirPath) && (
                  <IconButton title={item.path} size="small" sx={{ m: -1 }} component="span" onClick={clearBookmark}>
                    <ClearIcon />
                  </IconButton>
                )}
                {/* <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> */}
              </MenuItem>
            ))}
          </StyledTextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>キャンセル</Button>
          {state.registed ? (
            <Button disabled>
              <IconText.Success mr={0.5} text="登録済み" />
            </Button>
          ) : (
            <Button onClick={() => registerBookmark()}>登録</Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

Bookmark.propTypes = {
  dispState: PropTypes.object,
  handlePathClick: PropTypes.func
}

export default Bookmark
