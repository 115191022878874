import { Alert, Box, Button, DialogActions, DialogContent, IconButton, Paper } from '@mui/material'
import { FileUpload as FileUploadIcon } from '@mui/icons-material'
import { useReducer } from 'react'
import PropTypes from 'prop-types'
import { LoadButton, Dialog } from 'src/components'
import { useApi, createMessage } from 'src/util/'

const MAX_UPLOAD_COUNT = 10
const UploadDialog = ({ dialog, setDialog, dispState, handlePathClick }) => {
  console.log('UploadDialog')
  const request = useApi()

  const handleFileUpload = async () => {
    const fd = new FormData()
    const fileMtimes = {}
    for (let i = 0; i < dialog.files.length; i++) {
      const file = dialog.files[i]
      fd.append('uploadfile', file)
      fileMtimes[file.name] = file.lastModified
    }
    fd.append('remote_id', dispState.remote_id)
    fd.append('absolutePath', dispState.dirPath)
    // multipart/form-dataでは、ファイルの更新日時をサーバサイドに渡せない（Content-Disposition等にも含まれない）。
    // そのため、その他の入力フォーム項目として送信しサーバサイドではファイル名と突合して取得する。
    fd.append('fileMtimes', JSON.stringify(fileMtimes))

    await request.upload('/rmt/cmdPutFile', fd, () => {
      setDialog({ open: false })
      handlePathClick(dispState.dirPath)
    })
  }

  return (
    <Dialog title="ファイルアップロード" open={dialog.open} close={() => setDialog({ open: false })} maxWidth="md">
      <DialogContent sx={{ p: 2, pb: 0, color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem' }}>
        {dialog.messages.map((msg, i = 0) => (
          <Alert key={i} severity={msg.severity}>
            {msg.body}
          </Alert>
        ))}
        {/* 以下のファイルをアップロードします。 */}
        <Paper elevation={0} sx={{ px: 2, mt: 2 }}>
          {dialog.files.map((file, i = 0) =>
            i < MAX_UPLOAD_COUNT ? (
              <Box
                key={i}
                sx={{ py: 0.5 }}
              >{`${file.name} (${Math.ceil(Number.parseInt(file.size, 10) / 1024).toLocaleString()}KB)`}</Box>
            ) : i === MAX_UPLOAD_COUNT ? (
              <>...</>
            ) : (
              <></>
            )
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => document.getElementById('file-selects').click()}>ファイル選択</Button>
        <LoadButton type="submit" onClick={handleFileUpload} disabled={!dialog.ok}>
          送信
        </LoadButton>
      </DialogActions>
    </Dialog>
  )
}

UploadDialog.propTypes = {
  dialog: PropTypes.object,
  setDialog: PropTypes.func,
  dispState: PropTypes.object,
  handlePathClick: PropTypes.func
}

const _set = (state, obj) => {
  return { ...state, ...obj }
}
const FileUpload = ({ dispState, handlePathClick }) => {
  console.log('FileUpload')
  const [dialog, setDialog] = useReducer(_set, { open: false, files: [], ok: false, messages: [] })

  // Content-Type:multipart/form-data
  const handleFileUpload = (e) => {
    console.log('handleFileUpload')
    const fileSelects = document.getElementById('file-selects')
    if (fileSelects.files.length === 0) return

    dialog.messages.length = 0
    dialog.files = Array.from(fileSelects.files)
    if (dialog.files.length > MAX_UPLOAD_COUNT) {
      dialog.messages.push(
        createMessage('error', `※一度にアップロードできるファイル数は${MAX_UPLOAD_COUNT}個までです。`)
      )
    }

    const sizeNg = dialog.files.some((file) => file.size > 1024 * 1024 * 30)
    if (sizeNg) {
      dialog.messages.push(createMessage('error', '※30MBを超えるファイルはアップロードできません。'))
    }

    if (dialog.messages.length === 0) {
      dialog.messages.push(createMessage('info', '以下のファイルをアップロードします。'))
      dialog.ok = true
    } else {
      dialog.ok = false
    }

    dialog.open = true
    setDialog(dialog)
    e.target.value = ''
  }

  return (
    <>
      <label htmlFor="file-selects">
        <input type="file" id="file-selects" multiple style={{ display: 'none' }} onChange={handleFileUpload} />
        <IconButton size="small" sx={{ ml: 1, mr: 1 }} component="span">
          <FileUploadIcon />
        </IconButton>
      </label>
      <input type="hidden" id="file-selects-handle" onClick={handleFileUpload} />
      <UploadDialog dialog={dialog} setDialog={setDialog} dispState={dispState} handlePathClick={handlePathClick} />
    </>
  )
}

FileUpload.propTypes = {
  dispState: PropTypes.object,
  handlePathClick: PropTypes.func
}

export default FileUpload
