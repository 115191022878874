import { Box, Slide, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

export const SlideContainer = ({ state, sx, children, ...props }) => {
  // const containerRef = useRef(null)
  // const [height, setHeight] = useState(100)
  // state.height = height

  // useEffect(() => {
  //   state.setHeight = setHeight
  // }, [])

  // useEffect(() => {
  //   const observer = new ResizeObserver((entries) => {
  //     console.log('SlideContainer', entries)
  //     let maxChildrenHeight = 0
  //     for (let i = 0; i < elem.current.children.length; i++) {
  //       const item = elem.current.children[i]
  //       if (maxChildrenHeight < item.clientHeight) maxChildrenHeight = item.clientHeight
  //     }
  //     setHeight(maxChildrenHeight)
  //   })
  //   observer.observe(elem.current)
  // }, [])

  return (
    <Box {...props} sx={{ position: 'relative', overflow: 'hidden', width: '100%', transition: 'all 0.2s 0s', ...sx }}>
      {children}
    </Box>
  )
}

SlideContainer.propTypes = {
  state: PropTypes.object,
  sx: PropTypes.object,
  children: PropTypes.any
}
