import { Link as RouterLink } from 'react-router-dom'
import { Box } from '@mui/material'

const Logo = (props) => (
  <>
    <RouterLink to="/app" style={{ display: 'flex' }}>
      {/* <img alt="Logo" src="/static/secom_logo.png" height="22" {...props} /> */}
      <img alt="Logo" src="/static/logo.svg" height="28" {...props} />
    </RouterLink>
    <div style={{ paddingLeft: 8 }}>
      <Box component="span" sx={{ fontWeight: 'bold', fontSize: 13.5, letterSpacing: -0.1 }}>
        {process.env.REACT_APP_TITLE}
      </Box>
    </div>
  </>
)

export default Logo
