import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  TableBody,
  TableRow
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { useRef, useEffect, useReducer } from 'react'
import * as yup from 'yup'
import _ from 'lodash'
import {
  Dialog,
  DialogText,
  TextField,
  RenderingSuppress,
  MoreIconButton,
  MoreIconAddButton,
  StyledCell
} from 'src/components'
import { useApi, mergeDiff, createUpdateObj, setFormInit, useStateManage } from 'src/util/'

const CRON = yup
  .string()
  .required('必須項目です。')
  .matches(/^[0-9,\-*//]+$/i, '数値か[,-*/]が指定できます')

const CRONS = yup.object({
  cron_1: CRON,
  cron_2: CRON,
  cron_3: CRON,
  cron_4: CRON,
  cron_5: CRON
})

const DialogScheduleSetting = ({ open, onClose, callArg = {} }) => {
  console.log('DialogScheduleSetting')
  const request = useApi()

  const form = useForm({
    defaultValues: { batch_id: '', batch_file_name: '', cron_1: '', cron_2: '', cron_3: '', cron_4: '', cron_5: '' },
    resolver: yupResolver(CRONS)
  })

  const onSubmit = (values) => {
    const cron_formula = `${values.cron_1} ${values.cron_2} ${values.cron_3} ${values.cron_4} ${values.cron_5}`
    request.put('/bat/schedule', { batch_id: values.batch_id, cron_formula }, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const handleDelete = () => {
    request.delete('/bat/schedule', { batch_id: form.getValues('batch_id') }, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  useEffect(() => {
    if (!open) return
    let cron = ['*', '*', '*', '*', '*']
    if (callArg.cron_formula) {
      cron = callArg.cron_formula.split(' ')
      if (cron.length === 6) cron.shift()
    }

    setFormInit(form, {
      batch_id: callArg.batch_id,
      batch_file_name: callArg.batch_file_name,
      cron_1: cron[0],
      cron_2: cron[1],
      cron_3: cron[2],
      cron_4: cron[3],
      cron_5: cron[4]
    })
    form.trigger('_render')
  }, [open])

  return (
    <>
      <Dialog title="スケジュール設定" open={open} close={onClose} maxWidth="md">
        <DialogContent sx={{ overflow: 'hidden', p: 2 }}>
          <DialogText sx={{ mb: 2 }}>
            {String(form.getValues('batch_file_name') + 'のスケジュール（cron式）を入力してください。')}
          </DialogText>
          <Grid container justifyContent="space-around">
            <Grid item xs={2}>
              <TextField form={form} name="cron_1" autoFocus label="分*" />
            </Grid>
            <Grid item xs={2}>
              <TextField form={form} name="cron_2" label="時*" />
            </Grid>
            <Grid item xs={2}>
              <TextField form={form} name="cron_3" label="日*" />
            </Grid>
            <Grid item xs={2}>
              <TextField form={form} name="cron_4" label="月*" />
            </Grid>
            <Grid item xs={2}>
              <TextField form={form} name="cron_5" label="週*" />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ px: 2, justifyContent: 'space-between' }}>
          <Button onClick={handleDelete}>削除</Button>
          <Box>
            <Button onClick={onClose}>キャンセル</Button>
            <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
              設定
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

DialogScheduleSetting.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  callArg: PropTypes.object
}

const BATCH_SETTING = yup.object({
  _args: yup.array().of(
    yup.object({
      value: yup
        .string()
        .test('', '前後に空白は含められません。', (value) => !value?.match(/^\s|\s$/))
        .test('', '禁止文字（\\;|&`()$<>*?{}[]!）が含まれます。', (value) => !value?.match(/[\\;|&`()$<>*?{}[\]!]/))
    })
  ),
  description: yup.string().required()
})

const DialogBatchSetting = ({ open, onClose, callArg = {} }) => {
  console.log('DialogBatchSetting')
  const request = useApi()
  const beforeUpdate = useRef()

  const form = useForm({
    defaultValues: { batch_id: '', batch_file_name: '', _args: [{ no: 1, mask: false, value: '' }], description: '' },
    resolver: yupResolver(BATCH_SETTING)
  })

  const onSubmit = async (values) => {
    const updateObj = createUpdateObj(beforeUpdate.current, values)
    if (!Object.keys(updateObj).length) return true
    const batch_args = []
    const batch_args_mask = []
    values._args.forEach((elem) => {
      if (elem.mask && elem.value !== '') {
        batch_args.push(null)
        batch_args_mask.push(elem.value)
      } else {
        batch_args.push(elem.value)
        batch_args_mask.push(null)
      }
    })

    let success = false
    await request.patch(
      '/bat/program',
      { batch_id: values.batch_id, batch_args, batch_args_mask, description: updateObj.description },
      () => {
        success = true
      }
    )
    return success
  }

  useEffect(() => {
    if (!open) return
    request.get('/bat/program', { batch_id: callArg.batch_id }, ({ body }) => {
      const values = mergeDiff(form.getValues(), body)
      const length = Math.max(body.batch_args.length, body.batch_args_mask.length)
      for (let i = 0; i < length; i++) {
        values._args[i] = {
          no: i + 1,
          mask: body.batch_args_mask[i] ? true : false,
          value: body.batch_args[i] || body.batch_args_mask[i]
        }
      }
      beforeUpdate.current = _.cloneDeep(values)
      setFormInit(form, values)
      form.trigger('_render')
    })
  }, [open])

  const itemList = [
    {
      itemName: '引数の追加',
      call: (elem) => {
        form.getValues('_args').splice(elem.no, 0, { no: elem.no, mask: false, value: '' })
        form.getValues('_args').forEach((_elem, i) => {
          _elem.no = i + 1
        })
        form.setValue('_args', form.getValues('_args')) // 必要！
        form.trigger('_args')
      },
      disabled: () => form.getValues('_args').length >= 9
    },

    {
      itemName: '引数のマスク',
      body: (elem) => {
        return elem.mask ? 'OFF' : 'ON'
      },
      call: (elem) => {
        elem.mask = !elem.mask
        form.trigger('_render')
      }
    },

    {
      itemName: '引数の削除',
      call: (elem) => {
        form.getValues('_args').splice(elem.no - 1, 1)
        form.getValues('_args').forEach((_elem, i) => {
          _elem.no = i + 1
        })
        form.setValue('_args', form.getValues('_args'))
        form.trigger('_render')
      },
      disabled: (elem) => form.getValues('_args').length === 1 && elem.no === 1
    }
  ]

  const handleSubmit = async () => {
    let success
    await form.handleSubmit(async (v) => {
      success = await onSubmit(v)
    })()
    if (success) document.getElementById('pageReflesh').click()
  }

  const handleRunBatch = async () => {
    let success
    await form.handleSubmit(async (v) => {
      success = await onSubmit(v)
    })()
    if (success) {
      await request.post('/bat/runManually', { batch_id: form.getValues('batch_id') }, () => {
        document.getElementById('pageReflesh').click()
      })
    }
  }

  const close = {
    dialog: onClose,
    form: () => {
      form.reset()
      setFormInit(form) // _argsの参照を消す
    }
  }

  return (
    <>
      <Dialog title="バッチ: パラメータ設定" open={open} close={close} maxWidth="lg">
        <DialogContent sx={{ overflow: 'hidden', p: 2 }}>
          <DialogText sx={{ mb: 2 }}>
            {String(callArg.batch_file_name + 'の引数と、説明文を入力してください。')}
          </DialogText>
          <Grid container rowSpacing={2} columnSpacing={6} sx={{ pr: 6, mb: 3 }}>
            {form.getValues('_args').map((elem, i) => (
              <Grid key={elem.no} item xs={12} md={6} lg={4}>
                <TextField
                  type={elem.mask ? 'password' : ''}
                  fullWidth
                  error={Boolean(form.formState.errors._args?.[i])}
                  helperText={form.formState.errors._args?.[i]?.value?.message}
                  label={'バッチ引数' + elem.no}
                  form={form}
                  name={`_args.${i}.value`}
                  autoComplete="off"
                />
                {/* <StyledTextField type={elem.mask ? 'password' : ''} fullWidth
                  error={Boolean(formik.touched._args && formik.errors._args?.[i]?.value)}
                  helperText={formik.touched._args && formik.errors._args?.[i]?.value}
                  label={'バッチ引数' + (elem.no)}
                  name={`_args.${i}.value`}
                  value={formik.values._args[i].value}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  inputProps={{ maxLength: 128 }}
                /> */}
                <MoreIconAddButton itemList={itemList} callArg={elem} />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              form={form}
              name="description"
              label="バッチ説明"
              multiline
              fullWidth
              minRows={2}
              maxRows={10}
              inputProps={{ maxLength: 10000 }}
              size="middle"
            />
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ px: 2, justifyContent: 'space-between' }}>
          <Button onClick={handleRunBatch}>設定＆即時実行</Button>
          <Box>
            <Button
              onClick={() => {
                close.dialog()
                close.form()
              }}
            >
              キャンセル
            </Button>
            <Button type="submit" onClick={handleSubmit}>
              設定送信
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

DialogBatchSetting.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  callArg: PropTypes.object
}

// const _set = (state, obj) => { return { ...state, ...obj } }
const BatchTableBody = ({ list }) => {
  const request = useApi()
  // const [moreState, setMoreState] = useReducer(_set, { 1: {}, 2: {} })
  const { state, setValue, setObject } = useStateManage({ 1: {}, 2: {} })

  const handleDelete = (callArg) => {
    request.delete('/batRegister', { batch_id: callArg.batch_id }, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const itemList = [
    { itemName: 'スケジュール設定', call: (callArg) => setObject({ 1: { open: true, callArg } }) },
    { itemName: 'バッチ設定', call: (callArg) => setObject({ 2: { open: true, callArg } }) },
    {
      itemName: 'バッチ削除',
      call: (callArg) => handleDelete(callArg),
      disabled: (callArg) => callArg.not_deletable === 1
    }
  ]

  return (
    <TableBody>
      {list.map((row) => (
        <TableRow hover key={row.batch_id}>
          <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.batch_id}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.batch_file_name}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.cron_formula}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.status}</StyledCell>
          <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.ended_at}</StyledCell>
          <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.exit_cd}</StyledCell>
          <StyledCell sx={{ wordBreak: 'break-all' }}>{row.result}</StyledCell>
          <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.next_batch_id}</StyledCell>
          <StyledCell align="center" sx={{ p: '0', height: '42px' }}>
            <MoreIconButton itemList={itemList} callArg={row} icon="horiz" sx={{ m: '-8px' }} />
          </StyledCell>
        </TableRow>
      ))}
      <RenderingSuppress show={state[1].open}>
        <DialogScheduleSetting
          open={state[1].open}
          onClose={() => setValue([1, 'open'], false)}
          callArg={state[1].callArg}
        />
      </RenderingSuppress>
      <RenderingSuppress show={state[2].open}>
        <DialogBatchSetting
          open={state[2].open}
          onClose={() => setValue([2, 'open'], false)}
          callArg={state[2].callArg}
        />
      </RenderingSuppress>
    </TableBody>
  )
}

BatchTableBody.propTypes = {
  list: PropTypes.array.isRequired
}

export default BatchTableBody
