import { Button, Card, Checkbox, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, StyledCell, HeaderBox, FooterBox } from 'src/components'
import { useApi, diffForm, createDiffParams } from 'src/util/'

const ApiRouteUsable = ({ selectedGroup }) => {
  const request = useApi()
  const [response, setResponse] = useState({})
  const [open, setDialog] = useState(false)
  const [form, setForm] = useState([])

  const initTable = () => {
    if (selectedGroup.auth_group_id) {
      request.get('/apiRoute/usable', { auth_group_id: selectedGroup.auth_group_id }, ({ body }) => {
        setForm([...body])
        setResponse(body)
      })
    }
  }
  useEffect(initTable, [selectedGroup.auth_group_id])

  const handleDiffForm = (id, name, value) => setForm(diffForm(id, name, value, response, form))
  const handleDiffCheckbox = (e) => handleDiffForm(e.target.id, e.target.name, e.target.checked)

  const submitUpdate = (e) => {
    const reqestParams = createDiffParams(e, form, ['auth_group_id', 'route_id'], ['usable'])
    if (!reqestParams.length) return
    request.put(
      '/apiRoute/bulk',
      reqestParams,
      () => {
        setDialog(true)
        initTable()
      },
      () => initTable()
    )
  }

  return (
    <Card>
      <HeaderBox title="使用可能API">{/* <MoreIconButton itemList={itemList} /> */}</HeaderBox>
      {/* <Divider /> */}
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <StyledCell>ID</StyledCell>
              <StyledCell>メソッド</StyledCell>
              <StyledCell sx={{ minWidth: 100 }}>APIルート</StyledCell>
              <StyledCell sx={{ minWidth: 100 }}>説明</StyledCell>
              <StyledCell align="center">使用可</StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {form &&
              form.map((row, index) => (
                <TableRow hover key={row.route_id}>
                  <StyledCell>{row.route_id}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.method}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.route_path}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.description}</StyledCell>
                  <StyledCell align="center" turnOnBgColor={row.usable_diff} sx={{ p: '0' }}>
                    <Checkbox
                      id={index + ''}
                      name="usable"
                      checked={row.usable}
                      value={row.usable}
                      disabled={row.not_changeable}
                      color="secondary"
                      onChange={handleDiffCheckbox}
                    />
                  </StyledCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {form.length !== 0 && (
        <FooterBox>
          <Button type="submit" onClick={submitUpdate} variant="contained">
            更新
          </Button>
          <Dialog title="使用可能APIの更新" open={open} close={() => setDialog(false)}>
            使用可能APIが更新されました。
          </Dialog>
        </FooterBox>
      )}
    </Card>
  )
}

ApiRouteUsable.propTypes = {
  selectedGroup: PropTypes.object
}

export default ApiRouteUsable
