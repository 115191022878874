import { Button, DialogActions, DialogContent, Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import * as yup from 'yup'
import { TextField, Dialog, DialogText } from 'src/components'
import { useApi, isEqualDiff, setFormInit } from 'src/util/'

const CONTRACT_EDIT = yup.object({
  contract_name: yup.string().required('契約名は必須項目です。'),
  address: yup.string(),
  tel_no: yup.string(),
  description: yup.string()
})

const ContractTableMore = ({ dialog, setDialog }) => {
  console.log('ContractTableMore')
  const request = useApi()

  const form = useForm({
    defaultValues: { contract_id: '', contract_name: '', address: '', tel_no: '', description: '' },
    resolver: yupResolver(CONTRACT_EDIT)
  })

  const onSubmit = (values) => {
    if (isEqualDiff(values, dialog.selectedContract)) return
    request.patch('/contract', values, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  const handleReset = () => {
    setDialog({ editDialog: false })
    form.reset()
  }

  useEffect(() => {
    if (!dialog.editDialog) return
    setFormInit(form, dialog.selectedContract)
    form.trigger('_render')
  }, [dialog.editDialog])

  return (
    <Dialog title="契約情報の変更" open={dialog.editDialog} close={handleReset} onClose={handleReset} maxWidth="lg">
      <DialogContent sx={{ overflow: 'hidden', p: 2 }}>
        <DialogText
          sx={{ mb: 2 }}
        >{`「${dialog.selectedContract.contract_name}」の変更内容を入力してください。`}</DialogText>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="contract_name" label="契約名*" inputProps={{ maxLength: 64 }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="address" label="住所" inputProps={{ maxLength: 64 }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="tel_no" label="電話番号" inputProps={{ maxLength: 32 }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="description" label="説明" inputProps={{ maxLength: 64 }} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset}>キャンセル</Button>
        <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
          送信
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ContractTableMore.propTypes = {
  dialog: PropTypes.object,
  setDialog: PropTypes.func
}

export default ContractTableMore
