import { Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import * as yup from 'yup'
import { TextField, Dialog, HeaderBox, FooterBox } from 'src/components'
import { useApi, setFormInit, useMainRef } from 'src/util/'

const ACCOUNT = yup.object({
  user_name: yup.string().trim().required('名前は必須項目です。'),
  mail: yup.string().nullable().email('有効なメールアドレス形式でありません。')
})

const AccountInfo = () => {
  console.log('AccountInfo')
  const request = useApi()
  const [, render] = useOutletContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const ref = useMainRef()

  const form = useForm({
    defaultValues: { user_name: '', mail: '', auth_group_name: '', is_tow_auth: '' },
    resolver: yupResolver(ACCOUNT)
  })

  const onSubmit = (values) => {
    if (!ref.changeValues(values)) return
    request.put('/myInfo', values, ({ body }) => {
      render('user', 'name', body.data.user_name)
      setDialogOpen(true)
    })
  }

  const init = () => {
    request.get('/myInfo', null, ({ body }) => {
      console.log('AccountInfo - init')
      setFormInit(form, body.data)
      ref.setValues(form.getValues()) // is_tow_auth を二要素認証の削除表示に利用
    })
  }
  useEffect(init, [])

  return (
    <form autoComplete="off" noValidate style={{ height: '100%' }}>
      <Card sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
        <HeaderBox title="アカウントの情報" />
        <Divider />
        <CardContent sx={{ flex: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField form={form} name="user_name" label="名前" />
            </Grid>
            <Grid item xs={12}>
              <TextField form={form} name="mail" label="メール" inputProps={{ maxLength: 64 }} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                form={form}
                name="auth_group_name"
                label="権限グループ"
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <FooterBox>
          {/* <Button type="submit" color="primary" variant="contained"> */}
          <Button type="button" color="primary" variant="contained" onClick={form.handleSubmit(onSubmit)}>
            更新
          </Button>
          <Dialog title="アカウントの情報更新" open={dialogOpen} close={() => setDialogOpen(false)}>
            アカウント情報が更新されました。
          </Dialog>
        </FooterBox>
      </Card>
    </form>
  )
}

export default AccountInfo
