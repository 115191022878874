import { useNavigate } from 'react-router-dom'
import { useEffect, useLayoutEffect } from 'react'

const PageReflesh = () => {
  const navigate = useNavigate()
  useEffect(() => navigate(-1))
  return null
}

export default PageReflesh
