import {
  Box,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableRow
} from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  Dialog,
  DialogText,
  FileDragArea,
  LoadButton,
  MoreIconButton,
  MoreIconAddButton,
  RenderingSuppress,
  TextField,
  TextPassword
} from 'src/components'
import { useApi, useStateManage, loadSingleFile } from 'src/util/'

const REG_EDIT_FORM = yup.object({
  remote_name: yup.string().trim().required('リモート名は必須項目です。'),
  host: yup.string().trim().required('ホスト名は必須項目です。'),
  port: yup
    .number()
    .required('ポート番号は必須項目です。')
    .min(0, '0～65535の間を指定してください。')
    .max(65535, '0～65535の間を指定してください。'),
  user: yup.string().trim().required('ログイン名は必須項目です。')
  // password: yup.string().trim().required('パスワードは必須項目です。'),
})

const RegEditForm = ({ message, form, conn, expanded, setExpanded }) => {
  const itemList = [
    {
      itemName: '秘密鍵の入力',
      call: () => {
        if (expanded) form.setValue('private_key', '')
        setExpanded(!expanded)
      }
    },
    { itemName: '秘密鍵ファイルの選択', call: () => document.getElementById('file-select-2').click() }
  ]

  const handleFileSelect = (e) => {
    loadSingleFile('file-select-2', (reader, event) => {
      const pem = reader.result.trim()
      // 先頭行"-----BEGIN"、最終行"PRIVATE KEY-----"
      if (!pem.match(/^-----BEGIN [\s\S]* PRIVATE KEY-----$/g)) {
        alert('PEMファイル形式のPRIVATE KEYでありません。')
        return
      }
      setExpanded(true)
      form.setValue('private_key', pem)
    })
  }

  return (
    <>
      <DialogContent sx={{ p: 2 }}>
        <DialogText sx={{ mb: 2 }}>{message}</DialogText>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="remote_name" label="リモート名*" autoFocus />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="description" label="説明" inputProps={{ maxLength: 64 }} />
          </Grid>
          <Grid item xs={8}>
            <TextField form={form} name="host" label="ホスト名（またはIP）*" />
          </Grid>
          <Grid item xs={4}>
            <TextField form={form} name="port" label="ポート番号*" type="number" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField form={form} name="user" label="ログイン名*" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextPassword form={form} name="password" label="パスワード" sx={{ width: 'calc(100% - 36px)' }} />
            <MoreIconAddButton itemList={itemList} />
          </Grid>
          <input hidden type="file" id="file-select-2" onChange={handleFileSelect} style={{ display: 'none' }} />
          <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
            <Box sx={{ pl: 2, pt: 2 }}>
              <FileDragArea fileId="file-select-2" callback={handleFileSelect}>
                <TextField
                  form={form}
                  name="private_key"
                  label="秘密鍵（PEM形式）"
                  multiline
                  fullWidth
                  minRows={2}
                  maxRows={7}
                  inputProps={{ maxLength: 32000 }}
                  size="middle"
                />
              </FileDragArea>
            </Box>
          </Collapse>

          <Grid item xs={12}>
            <TextField select form={form} name="jump_remote_id" label="踏み台サーバ">
              <MenuItem key={0} value="">
                {'　'}
              </MenuItem>
              {conn.list.map((item) => (
                <MenuItem key={item.remote_id} value={item.remote_id}>
                  {item.remote_name + '（' + item.host + ':' + item.port + '）'}
                  {item.description && item.description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={form.handleReset}>キャンセル</Button>
        <LoadButton onClick={form.onSubmit} type="submit">
          送信
        </LoadButton>
      </DialogActions>
    </>
  )
}

RegEditForm.propTypes = {
  message: PropTypes.string,
  form: PropTypes.object,
  conn: PropTypes.object,
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func
}

const DialogRegister = ({ open, onClose, conn }) => {
  console.log('Register')
  const request = useApi()
  const [expanded, setExpanded] = useState(false)

  const form = useForm({
    defaultValues: {
      remote_name: '',
      description: '',
      host: 'localhost',
      port: '22',
      user: '',
      password: '',
      private_key: '',
      jump_remote_id: ''
    },
    resolver: yupResolver(REG_EDIT_FORM)
  })

  form.handleReset = () => {
    form.reset()
    setExpanded(false)
    onClose()
  }

  form.onSubmit = async () => {
    await form.handleSubmit(async (values) => {
      await request.post('/rmt/sshConnection', values, ({ body }) => {
        form.handleReset()
        conn.init(body.remote_id)
      })
    })()
  }

  return (
    <Dialog
      title="リモート接続の登録"
      open={open}
      close={{
        dialog: onClose,
        form: () => {
          form.reset()
          setExpanded(false)
        }
      }}
      maxWidth="lg"
    >
      <RegEditForm
        message="登録する接続情報を入力してください。"
        form={form}
        conn={conn}
        expanded={expanded}
        setExpanded={setExpanded}
      />
    </Dialog>
  )
}

DialogRegister.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  conn: PropTypes.object
}

const DialogEdit = ({ open, onClose, conn }) => {
  console.log('Edit')
  const request = useApi()
  const { state, setObject } = useStateManage({ expanded: false, reseter: 0 })

  const form = useForm({
    defaultValues: {
      remote_name: '',
      description: '',
      host: '',
      port: '',
      user: '',
      password: '',
      private_key: '',
      jump_remote_id: ''
    },
    resolver: yupResolver(REG_EDIT_FORM)
  })

  const close = {
    form: () => {
      form.reset()
      setObject({ expanded: false, reseter: state.reseter + 1 })
    },
    dialog: onClose
  }

  form.handleReset = () => {
    close.form()
    close.dialog()
  }

  form.onSubmit = async () => {
    await form.handleSubmit(async (values) => {
      await request.patch('/rmt/sshConnection', values, ({ body }) => {
        form.handleReset()
        conn.init(values.remote_id)
      })
    })()
  }

  useEffect(() => {
    if (conn.selectedKey === '') return
    request.get('/rmt/sshConnection', { remote_id: conn.selectedKey }, ({ body }) => {
      form.setValue('remote_id', conn.selectedKey)
      form.setValue('remote_name', body.remote_name)
      form.setValue('description', body.description || '')
      form.setValue('host', body.host)
      form.setValue('port', body.port)
      form.setValue('user', body.user)
      form.setValue('jump_remote_id', body.jump_remote_id || '')
    })
  }, [conn.selectedKey, state.reseter])

  return (
    <Dialog title="リモート接続の編集" open={open} close={close} maxWidth="lg">
      <RegEditForm
        message="変更する接続情報を入力してください。"
        form={form}
        conn={conn}
        expanded={state.expanded}
        setExpanded={(expanded) => setObject({ expanded })}
      />
    </Dialog>
  )
}

DialogEdit.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  conn: PropTypes.object
}

const TH = styled('th')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: '400',
  lineHeight: '1.43',
  borderLeftWidth: '1px',
  borderBottomWidth: '1px',
  padding: '6px 8px'
}))

const TD = styled('td')`
  line-height: 1.43;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-width: 1px;
  padding: 6px 8px;
`

const DialogDetail = ({ open, onClose, conn }) => {
  console.log('DialogDetail')
  const request = useApi()
  const [body, setBody] = useState({})

  useEffect(() => {
    if (conn.selectedKey === '' || !open) return
    request.get('/rmt/osInfo', { remote_id: conn.selectedKey }, (resp) => {
      setBody(resp.body)
    })
  }, [open])

  return (
    <Dialog
      title={`リモート先OSの詳細${body.updated_at ? '（' + body.updated_at + '取得）' : ''}`}
      open={open}
      close={onClose}
      maxWidth="lg"
    >
      <DialogContent sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Table sx={{ borderCollapse: 'separate', borderTopWidth: '1px', borderRightWidth: '1px' }} size="small">
              <TableBody>
                <TableRow sx={{}}>
                  <TH width="25%">OS</TH>
                  <TD>{body.os}</TD>
                </TableRow>
                <TableRow>
                  <TH width="25%">エディション</TH>
                  <TD>{body.edition}</TD>
                </TableRow>
                <TableRow>
                  <TH width="25%">カーネルビット数</TH>
                  <TD>{body.kernel_bit}</TD>
                </TableRow>
                <TableRow>
                  <TH width="25%">ホスト名</TH>
                  <TD>{body.host_name}</TD>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} md={6}>
            <Table sx={{ borderCollapse: 'separate', borderTopWidth: '1px', borderRightWidth: '1px' }} size="small">
              <TableBody>
                <TableRow>
                  <TH width="25%">CPU</TH>
                  <TD>{body.cpu}</TD>
                </TableRow>
                <TableRow>
                  <TH width="25%">プロセッサ数</TH>
                  <TD>{body.processors}</TD>
                </TableRow>
                <TableRow>
                  <TH width="25%">メモリ</TH>
                  <TD>{body.memory}</TD>
                </TableRow>
                <TableRow>
                  <TH width="25%">HD</TH>
                  <TD>{body.hd}</TD>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

DialogDetail.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  conn: PropTypes.object
}

const RemoteSelectMore = ({ conn }) => {
  console.log('RemoteSelectMore')
  const request = useApi()
  const { state, setValue, setObject } = useStateManage({
    open1: false,
    open2: false,
    open3: false,
    delMsg: '',
    open4: false
  })

  const itemList = [
    { itemName: 'リモート接続の登録', call: () => setValue('open1', true) },
    { itemName: 'リモート接続の編集', call: () => setValue('open2', true), disabled: () => conn.selectedKey === '' },
    {
      itemName: 'リモート接続の削除',
      call: () => {
        const selected = conn.getRecord(conn)
        setObject({ open3: true, delMsg: `${selected.remote_name}（${selected.host}:${selected.port}）` })
      },
      disabled: () => conn.selectedKey === ''
    },
    { itemName: 'リモート先OSの詳細', call: () => setValue('open4', true), disabled: () => conn.selectedKey === '' }
  ]

  const handleDelete = (_selected) => {
    request.delete('/rmt/sshConnection', { remote_id: _selected }, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  return (
    <>
      <MoreIconButton itemList={itemList} sx={{ my: '-8px' }} />
      <RenderingSuppress show={state.open1}>
        <DialogRegister open={state.open1} onClose={() => setValue('open1', false)} conn={conn} />
      </RenderingSuppress>
      <RenderingSuppress show={state.open2}>
        <DialogEdit open={state.open2} onClose={() => setValue('open2', false)} conn={conn} />
      </RenderingSuppress>
      <RenderingSuppress show={state.open3}>
        <Dialog title="リモート接続の削除" open={state.open3} close={() => setValue('open3', false)}>
          <DialogContent>
            <DialogText>{'「' + state.delMsg + '」を削除します。'}</DialogText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setValue('open3', false)}>キャンセル</Button>
            <Button onClick={() => handleDelete(conn.selectedKey)}>送信</Button>
          </DialogActions>
        </Dialog>
      </RenderingSuppress>
      <RenderingSuppress show={state.open4}>
        <DialogDetail open={state.open4} onClose={() => setValue('open4', false)} conn={conn} />
      </RenderingSuppress>
    </>
  )
}

RemoteSelectMore.propTypes = {
  conn: PropTypes.object
}

export default RemoteSelectMore
