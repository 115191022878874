import PropTypes from 'prop-types'
import { Dialog } from 'src/components/show/Dialog'
import { Pagination as PaginationTag } from '@mui/material'

const Pagination = ({ state, setFormRequest, closeRetryDialog }) => {
  const handleChange = (e, pageNo) => {
    if (pageNo === state.pageNo) return
    setFormRequest(null, { btn: 'page', pageNo })
  }

  return (
    <>
      <PaginationTag color="primary" count={state.pageTotal} size="small" page={state.pageNo} onChange={handleChange} />
      <Dialog title="検索条件の変更" open={state.retryDialog} close={closeRetryDialog}>
        検索条件が変更されています。 再度「検索」を実施した後に、選択ください。
      </Dialog>
    </>
  )
}

Pagination.propTypes = {
  state: PropTypes.object,
  setFormRequest: PropTypes.func,
  // retryDialog: boolean,
  closeRetryDialog: PropTypes.func
}

export default Pagination
